import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/Services/data.service";
import * as moment from "moment-timezone";

@Component({
  selector: "app-program-list",
  templateUrl: "./program-list.component.html",
  styleUrls: ["./program-list.component.scss"],
})
export class ProgramListComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  isChecked: boolean = false;
  programData: any = [];
  // openData:any =[];
  shiftsArr: any = [];
  errmsg: string = "";
  validationform: FormGroup;
  // targetPart: { shiftName: string; targetCount: number; partcapacitypershift:number }[] = [];
  targetPart = [];
  editProgId;
  action: any;
  currentPage = 1;
  itemsPerPage = 3;
  pageSize;
  submitted: boolean = false;
  pages: any;
  delprogramId = "";
  user: any;
  custId: any;
  runningTime: any = 0;
  loadingTime: any = 0;
  unloadingTime: any = 0;
  constructor(
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = this.user["user"]["custId"];
    this.breadCrumbItems = [
      { label: "Configuration" },
      { label: "Program List", active: true },
    ];
    this.getProgramData();
    this.getshiftDetails();
  }

  getshiftDetails() {
    this.shiftsArr = [];
    this.dataservice.get(`api/shift?custId=${this.custId}`).subscribe((res) => {
      console.log(res, "res");
      if (res) {
        this.shiftsArr = res;
        this.targetPart = this.shiftsArr.map((shift) => ({
          shiftName: shift.shiftName,
          partcapacitypershift: 0,
          // targetCount: 0,
        }));
      }
    });
  }

  getProgramData() {
    this.dataservice.get("programName/masterList/" + this.custId).subscribe(
      (res) => {
        if (res) {
          this.programData = res["result"];
          console.log(this.programData, "this.programData");
        }
      },
      (error: any) => {
        console.log(error);
        this.errmsg = "Server Unreachable";
        // this.programData = [];
        // this.programData = [
        //   {
        //     programId: 152,
        //     programName: "Gear00152",
        //     partName: "Gear",
        //     partsPerCycle: 5,
        //     runningTime: 150,
        //     loadingTime: 150,
        //     unloadingTime: 150,
        //     totalcycleTime: 450,
        //     isCycleTime: true,
        //     targetPart: [
        //       {
        //         shiftName: "shift1",
        //         // targetCount:50,
        //         partcapacitypershift: 150,
        //       },
        //     ],
        //   },
        //   {
        //     programId: 153,
        //     programName: "Gear152",
        //     partName: "Gear",
        //     partsPerCycle: 2,
        //     isCycleTime: false,
        //     targetPart: [
        //       {
        //         shiftName: "shift1",
        //         // targetCount:50,
        //         partcapacitypershift: 50,
        //       },
        //     ],
        //   },
        // ];
        // this.toastr.error("Server Unreachable");
      }
    );
  }

  initializeForm() {
    if (this.isChecked) {
      this.validationform = this.formBuilder.group({
        programId: [
          this.validationform?.value.programId || "",
          [Validators.required],
        ],
        programName: [
          this.validationform?.value.programName || "",
          [Validators.required],
        ],
        partName: [
          this.validationform?.value.partName || "",
          Validators.required,
        ],
        partsPerCycle: [
          this.validationform?.value.partsPerCycle || "",
          Validators.required,
        ],
        runningTime: [
          this.validationform?.value.runningTime || "",
          [Validators.required],
        ],
        loadingTime: [
          this.validationform?.value.loadingTime || "",
          [Validators.required],
        ],
        unloadingTime: [
          this.validationform?.value.unloadingTime || "",
          Validators.required,
        ],
      });
    } else if (!this.isChecked) {
      this.validationform = this.formBuilder.group({
        programId: [
          this.validationform?.value.programId || "",
          [Validators.required],
        ],
        programName: [
          this.validationform?.value.programName || "",
          [Validators.required],
        ],
        partName: [
          this.validationform?.value.partName || "",
          Validators.required,
        ],
        partsPerCycle: [
          this.validationform?.value.partsPerCycle || "",
          Validators.required,
        ],
      });
    }
    // if (this.action == "Add") {
    //   if (this.isChecked) {
    //     this.validationform = this.formBuilder.group({
    //       programId: ["", [Validators.required]],
    //       programName: ["", [Validators.required]],
    //       partName: ["", Validators.required],
    //       partsPerCycle: ["", Validators.required],
    //       runningTime: ["", [Validators.required]],
    //       loadingTime: ["", [Validators.required]],
    //       unloadingTime: ["", Validators.required],
    //     });
    //   } else if (!this.isChecked) {
    //     this.validationform = this.formBuilder.group({
    //       programId: ["", [Validators.required]],
    //       programName: ["", [Validators.required]],
    //       partName: ["", Validators.required],
    //       partsPerCycle: ["", Validators.required],
    //     });
    //   }
    // }
  }

  get form() {
    return this.validationform.controls;
  }

  onCheckboxChange(e) {
    console.log(this.isChecked, "event", e.target.value, this.validationform);
    if (this.action == "Add") {
      this.initializeForm();
      this.targetPart = this.targetPart.map((part) => ({
        ...part,
        // targetCount: 0,
        partcapacitypershift: 0,
      }));
      console.log(this.targetPart, "df(((((((((((((");
    }
  }

  openModal(content: any, data: any, act: any) {
    this.action = act;
    this.isChecked = data["isCycleTime"] || this.isChecked;
    this.modalService.open(content, { backdrop: "static", centered: true });
    if (act === "Add") {
      this.action = "Add";
      // this.validationform=[];
      this.targetPart = this.shiftsArr.map((shift) => ({
        shiftName: shift.shiftName,
        partcapacitypershift: 0,
        // targetCount: 0,
      }));
      this.isChecked = false;
      this.initializeForm();
    } else if (act === "Edit") {
      console.log(data,"")
      this.isChecked = data["isCycleTime"];
      if (data) {
        // this.openData = data['targetPart']
        this.targetPart = data["targetPart"];
        console.log(this.targetPart, data, "targetPart");
        // console.log(this.openData,"this.openData")
        this.editProgId = data.id;;
        this.initializeForm();

        this.pathValue(this.validationform, data);
        console.log(data.programId, this.validationform.value);
        this.runningTime = this.validationform.value.runningTime;
        this.loadingTime = this.validationform.value.loadingTime;
        this.unloadingTime = this.validationform.value.unloadingTime;
        this.validationform.get("programId").disable();
        this.validationform.get("programName").disable();
        this.validationform.get("partName").disable();
      }
    }
  }

  openModalDelete(deletetemp, data) {
    this.delprogramId = data.id;
    this.modalService.open(deletetemp, { backdrop: "static", centered: true });
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      controls[key].patchValue(data[key]);
    }
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    console.log(pageNum, this.pageSize, "pageNum");
  }
  selectUnloadingTime(e) {
    this.unloadingTime = Number(e.target.value);
  }
  selectLoadingTime(e) {
    this.loadingTime = Number(e.target.value);
  }
  selectRunningTime(e) {
    this.runningTime = Number(e.target.value);
  }
  calculateshiftTarget(shiftName, time) {
    let totalSeconds = moment.duration(time).asSeconds();
    console.log(
      totalSeconds,
      this.runningTime,
      this.loadingTime,
      this.unloadingTime
    );
    let result = Math.round(
      Number(
        totalSeconds /
          (this.runningTime + this.loadingTime + this.unloadingTime)
      )
    );
    const index = this.targetPart.length
      ? this.targetPart.findIndex((part) => part.shiftName === shiftName)
      : 0;
    if (index !== -1) {
      if (result === Infinity) {
        this.targetPart[index].partcapacitypershift = 0;
      } else {
        this.targetPart[index].partcapacitypershift = result;
      }
      console.log(this.targetPart, "this.targetPart");
    }
    return result === Infinity ? 0 : result;
  }
  updatepartcapacitypershift(shiftName: string, value: number) {
    const index = this.targetPart.findIndex(
      (part) => part.shiftName === shiftName
    );
    if (index !== -1) {
      this.targetPart[index].partcapacitypershift = value;
      console.log(this.targetPart, "this.targetPart");
    }
  }
  // updateTargetPart(shiftName: string, targetCount: number): void {
  //   const index = this.targetPart.findIndex((part) => part.shiftName === shiftName);
  //   if (index !== -1) {
  //     this.targetPart[index].targetCount = targetCount;
  //     console.log(this.targetPart,"this.targetPart")
  //   }
  // }

  saveData() {
    this.submitted = true;
    if (this.validationform.valid) {
      if (this.isChecked) {
        this.validationform.get("programId").enable();
        this.validationform.get("programName").enable();
        this.validationform.get("partName").enable();
        this.pages = {};
        this.pages["custId"] = this.custId;
        this.pages["programId"] = this.validationform.value.programId;
        this.pages["programName"] = this.validationform.value.programName;
        this.pages["partName"] = this.validationform.value.partName;
        this.pages["partsPerCycle"] = this.validationform.value.partsPerCycle;
        this.pages["runningTime"] = this.validationform.value.runningTime;
        this.pages["loadingTime"] = this.validationform.value.loadingTime;
        this.pages["unloadingTime"] = this.validationform.value.unloadingTime;
        // this.pages["plannedPart"] = this.targetPart;
        this.pages["isCycleTime"] = true;
        this.pages["targetPart"] = this.targetPart;
      } else if (!this.isChecked) {
        this.validationform.get("programId").enable();
        this.validationform.get("programName").enable();
        this.validationform.get("partName").enable();
        this.pages = {};
        this.pages["custId"] = this.custId;
        this.pages["programId"] = this.validationform.value.programId;
        this.pages["programName"] = this.validationform.value.programName;
        this.pages["partName"] = this.validationform.value.partName;
        this.pages["partsPerCycle"] = this.validationform.value.partsPerCycle;
        // this.pages["plannedPart"] = this.targetPart;
        this.pages["isCycleTime"] = false;
        this.pages["targetPart"] = this.targetPart;
      }
      console.log(this.pages, "pages");
      if (this.action == "Add") {
        this.dataservice
          .post("programName/masterList", this.pages)
          .subscribe((data) => {
            if (data) {
              this.cancel();
              this.toastr.success("Program Added Successfully");
              this.getProgramData();
            }
          });
      } else if (this.action == "Edit") {
        console.log(this.editProgId,this.pages)
        this.dataservice
          .put("programName/masterList/" + this.editProgId, this.pages)
          .subscribe((res) => {
            if (res) {
              this.cancel();
              this.toastr.success("Program Updated Successfully");
              this.getProgramData();
            }
          });
      }
    } else {
      return this.validationform.controls;
    }
  }

  cancel() {
    this.submitted = false;
    this.action = "";
    this.validationform.reset();
    this.modalService.dismissAll();
  }

  delete(id) {
    this.dataservice.delete("programName/masterList/" + id).subscribe((res) => {
      if (res) {
        this.toastr.success("Program deleted Successfully");
        this.modalService.dismissAll();
        this.getProgramData();
      }
    });
  }
}
