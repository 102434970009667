import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/Services/data.service";
import {
  MustMatch,
  ngxphoneNumberValidator,
} from "src/app/shared/Services/custom-validator";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  // breadcrumb items
  custId:any;
  breadCrumbItems: Array<{}>;
  action: any;
  submitted: boolean = false;
  usersData: any = [];
  validationform: FormGroup;
  errmsg: string = "";
  delUserId = "";
  currentPage = 1;
  itemsPerPage = 3;
  pageSize;
  editUserId;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedStates,
  ];

  constructor(
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    let userData = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = userData["user"]["custId"];
    this.breadCrumbItems = [
      { label: "Configuration" },
      { label: "Users", active: true },
    ];
    this.getUser();
  }

  getUser() {
    this.dataservice.get("api/user").subscribe((res) => {
      console.log(res);
      if (res) {
        this.usersData = res;
      } else {
        this.usersData = [];
      }
      (error) => {
        console.log(error);
        this.errmsg = "Server Unreachable";
        this.usersData = [];
        this.toastr.error("Server Unreachable");
      };
    });
  }

  initializeForm() {
    this.validationform = this.formBuilder.group(
      {
        name: ["", [Validators.required]],
        custId:[this.custId,[Validators.required]],
        mobileNumber: ["", [Validators.required, ngxphoneNumberValidator]],
        role: ["", [Validators.required]],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
          ],
        ],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmpwd: ["", Validators.required],
      },
      {
        validator: MustMatch("password", "confirmpwd"),
      }
    );
  }

  get form() {
    return this.validationform.controls;
  }

  openModal(content: any, data: any, act: any) {
    this.fieldTextType = false;
    this.fieldTextType2 = false;
    this.action = act;
    this.modalService.open(content, { backdrop: "static", centered: true });
    if (act === "Add") {
      this.initializeForm();
    } else if (act === "Edit") {
      if (data) {
        console.log(data, "data");
        this.editUserId = data.id;
        this.initializeForm();
        this.pathValue(this.validationform, data);
        this.validationform.get("mobileNumber").disable();
        this.validationform.get("confirmpwd").disable();
      }
    }
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      controls[key].patchValue(data[key]);
    }
  }

  editModal(content, type) {
    if (type === "edit") {
      this.modalService.open(content, { centered: true });
    }
  }

  saveData() {
    this.submitted = true;
    console.log(this.validationform, this.validationform.value);
    if (this.validationform.invalid) {
      if (
        this.validationform.controls["mobileNumber"].errors.invalidPhoneNumber
      ) {
        this.toastr.error("Invalid phone number");
      } else if (this.validationform.controls["mobileNumber"].errors) {
        this.toastr.error("Please enter Mobile Number");
      } else {
        return this.validationform.controls;
      }
    } else {
      if (this.action == "Add") {
        this.usersData.push(this.validationform.value);
        this.cancel();
        // Add API
        this.dataservice
          .post("api/user", this.validationform.value)
          .subscribe((res) => {
            console.log(res, "response");
            if (res) {
              this.cancel();
              this.toastr.success("User Added Successfully");
              this.getUser();
            }
          });
      } else {
        // Update API
        this.dataservice
          .put("api/user/" + this.editUserId, this.validationform.value)
          .subscribe((res) => {
            console.log(res, "response");
            if (res) {
              this.cancel();
              this.toastr.success("User Updated Successfully");
              this.getUser();
            }
          });
      }
    }
  }
  openModalDelete(deletetemp, user) {
    console.log(user, "user");
    this.delUserId = user.id;
    this.modalService.open(deletetemp, { backdrop: "static", centered: true });
  }

  cancel() {
    this.submitted = false;
    this.action = "";
    this.modalService.dismissAll();
  }

  delete(id) {
    console.log(id, "idddd");
    this.dataservice.delete("api/user/" + id).subscribe((res) => {
      if (res) {
        this.toastr.success("User deleted Successfully");
        console.log(res, "res");
        this.modalService.dismissAll();
        this.getUser();
      }
    });
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    console.log(pageNum, this.pageSize, "pageNum");
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    this.toastr.warning("Please Type the password");
  }

  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  fieldTextType2: boolean;
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }
}
