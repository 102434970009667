<div class="container-fluid">
  <app-pagetitle
    title="Customers"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <a
              class="btn btn-success mb-2"
              (click)="openModal(content, '', 'Add')"
            >
              <i class="mdi mdi-plus mr-2"></i> Add Program
            </a>
          </div>
          <div class="table-responsive mt-3" *ngIf="programData.length != 0">
            <table
              class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%"
            >
              <thead class="thead-light">
                <tr>
                  <th>Program Id</th>
                  <th>Program Name</th>
                  <th>Part Name</th>
                  <th>Running Time (sec)</th>
                  <th>Loading Time (sec)</th>
                  <th>Unload Time (sec)</th>
                  <th>Total Cycle Time (sec)</th>
                  <th style="width: 120px">Action</th>
                </tr>
              </thead>
              <tbody
                *ngFor="
                  let item of programData
                    | slice : pageSize
                    | slice : 0 : itemsPerPage;
                  let i = index
                "
              >
                <tr>
                  <td>{{ item.programId }}</td>
                  <td>{{ item.programName }}</td>
                  <td>{{ item.partName }}</td>
                  <td>{{ item.runningTime ? item.runningTime:'-' }}</td>
                  <td>{{ item.loadingTime ? item.loadingTime:'-' }}</td>
                  <td>{{ item.unloadingTime ? item.unloadingTime:'-' }}</td>
                  <td>{{ item.totalcycleTime ? item.totalcycleTime:'-' }}</td>
                  <td>
                    <a
                      (click)="openModal(content, item, 'Edit')"
                      class="mr-3 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      style="cursor: pointer"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      style="cursor: pointer"
                      (click)="openModalDelete(deletetemp, item)"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="programData?.length > 5">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="programData?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
          <div class="card" *ngIf="programData.length == 0">
            <span *ngIf="errmsg == ''"
              ><strong>No Program Found. Please Add Program</strong></span
            >
            <span *ngIf="errmsg != ''"
              ><strong>{{ errmsg }}</strong></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Program model  -->
<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ action }} Program</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form
        (ngSubmit)="saveData()"
        [formGroup]="validationform"
        autocomplete="off"
      >
        <div class="row">
          <div class="form-group col-md-6">
            <label for="programId">Program Id</label>
            <input
              type="number"
              class="form-control"
              id="programId"
              placeholder="Enter Program Id"
              formControlName="programId"
              [ngClass]="{ 'is-invalid': submitted && form.programId.errors }"
            />
            <div
              *ngIf="submitted && form.programId.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.programId.errors.required"
                >Program Id is mandatory.</span
              >
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="programName">Program Name</label>
            <input
              type="text"
              class="form-control"
              id="programName"
              placeholder="Enter Program Name"
              formControlName="programName"
              [ngClass]="{ 'is-invalid': submitted && form.programName.errors }"
            />
            <div
              *ngIf="submitted && form.programName.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.programName.errors.required"
                >Program Name is mandatory.</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="partName">Part Name</label>
            <input
              type="text"
              class="form-control"
              id="partName"
              placeholder="Enter Part Name"
              formControlName="partName"
              [ngClass]="{ 'is-invalid': submitted && form.partName.errors }"
            />
            <div
              *ngIf="submitted && form.partName.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.partName.errors.required"
                >Part Name is mandatory.</span
              >
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="partsPerCycle">Part Per Cycle</label>
            <input
              type="number"
              class="form-control"
              id="partsPerCycle"
              placeholder="Enter Part per Cycle"
              formControlName="partsPerCycle"
              [ngClass]="{ 'is-invalid': submitted && form.partsPerCycle.errors }"
            />
            <div
              *ngIf="submitted && form.partsPerCycle.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.partsPerCycle.errors.required"
                >Part per cycle is mandatory.</span
              >
            </div>
          </div>
          
        </div>
        <div class="row">

          <div  class="form-group col-md-6 d-flex">
            <label for="type">To configure CycleTime</label>&nbsp;
            <input
            class="form-control"
            style="width: 15px;margin-top: -10px;"
            type="checkbox"
            [(ngModel)]="isChecked"
            [ngModelOptions]="{ standalone: true }"
            (change)="onCheckboxChange($event)"
            id="type"
        />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6" *ngIf="isChecked">
            <label for="runningTime">Running Time (sec)</label>
            <input
              type="number"
              class="form-control"
              id="runningTime"
              placeholder="Enter Running Time"
              (change)="selectRunningTime($event)"
              formControlName="runningTime"
              [ngClass]="{ 'is-invalid': submitted && form.runningTime.errors }"
            />
            <div
              *ngIf="submitted && form.runningTime.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.runningTime.errors.required"
                >Running Time is mandatory.</span
              >
            </div>
          </div>
          <div class="form-group col-md-6" *ngIf="isChecked">
            <label for="loadingTime">Loading Time (sec)</label>
            <input 
              type="number"
              class="form-control"
              id="loadingTime"
              placeholder="Enter Loading Time"
              (change)="selectLoadingTime($event)"
              formControlName="loadingTime"
              [ngClass]="{ 'is-invalid': submitted && form.loadingTime.errors }"
            />
            <div
              *ngIf="submitted && form.loadingTime.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.loadingTime.errors.required"
                >Loading Time is mandatory.</span
              >
            </div>
          </div>
          <div class="form-group col-md-6" *ngIf="isChecked">
            <label for="unloadingTime">Unloading Time (sec)</label>
            <input
              type="number"
              class="form-control"
              id="unloadingTime"
              placeholder="Enter Unloading Time"
              (change)="selectUnloadingTime($event)"
              formControlName="unloadingTime"
              [ngClass]="{
                'is-invalid': submitted && form.unloadingTime.errors
              }"
            />
            <div
              *ngIf="submitted && form.unloadingTime.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.unloadingTime.errors.required"
                >Unloading Time is mandatory.</span
              >
            </div>
          </div>
          <div class="table-responsive">
            <table
              class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%"
            >
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Duration</th>
                  <th>Part Capacity Per Shift</th>
                  <!-- <th>Planned Target</th> -->
                </tr>
              </thead>
              <tbody *ngFor="let item of shiftsArr; let j = index">
                <tr>
                  <td>{{ item.shiftName }}</td>
                  <td>{{ item.duration }}</td>
                  <td *ngIf="isChecked">{{ calculateshiftTarget(item.shiftName,item.duration) }}</td>
                  <td *ngIf="!isChecked"> 
                    <input
                      id="partshiftcapacity{{ j }}"
                      class="form-control"
                      name="partshiftcapacity{{ j }}"
                      placeholder="Part Capacity per Shift"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="targetPart[j]['partcapacitypershift']"
                      (input)="
                        updatepartcapacitypershift(item.shiftName,$event.target.value)
                      "
                    />
                  </td>
                  <!-- <td>
                    <input
                      id="plannedTarget{{ j }}"
                      class="form-control"
                      name="plannedTarget{{ j }}"
                      placeholder="Planned Target"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="targetPart[j]['targetCount']"
                      (input)="
                        updateTargetPart(item.shiftName, $event.target.value)
                      "
                    />
                 
                  </td> -->

          
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">Save</button>
          <button type="button" class="btn btn-danger ml-1" (click)="cancel()">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #deletetemp role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Delete Program</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="example-container">
      <form>
        <div>
          <p>Are you sure to Delete?</p>
        </div>
        <button
          type="submit"
          class="btn btn-danger round waves-effect headr"
          (click)="delete(delprogramId)"
        >
          Delete
        </button>
        &nbsp;
        <button
          type="submit"
          class="btn btn-info round waves-effect headr"
          (click)="modal('Cross click')"
        >
          Cancel
        </button>
      </form>
    </div>
  </div>
</ng-template>
