<div class="container-fluid">
  <app-pagetitle
    title="Customers"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div *ngIf="errmsg == ''">
            <button
            class="btn btn-success mb-2"
            (click)="openModal(content, '', 'Add')"
            [disabled]="usersData.length >= 2"
          >
            <i class="mdi mdi-plus mr-2"></i> Add Users
          </button>
          </div>
          <div class="table-responsive mt-3" *ngIf="usersData.length != 0">
            <table
              class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%"
            >
              <thead class="thead-light">
                <tr>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Role</th>
                  <!-- <th>Joining Date</th> -->
                  <th style="width: 120px">Action</th>
                </tr>
              </thead>
              <tbody
                *ngFor="
                  let item of usersData
                    | slice : pageSize
                    | slice : 0 : itemsPerPage;
                  let i = index
                "
              >
                <tr>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.mobileNumber }}</td>
                  <td>{{ item.role }}</td>

                  <td>
                    <a
                      (click)="openModal(content, item, 'Edit')"
                      class="mr-3 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      style="cursor: pointer"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      style="cursor: pointer"
                      (click)="openModalDelete(deletetemp, item)"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="usersData?.length > 2">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="usersData?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
          <div class="card" *ngIf="usersData.length == 0">
            <span *ngIf="errmsg == ''"
              ><strong>No users Found. Please Add Users</strong></span
            >
            <span *ngIf="errmsg != ''"
              ><strong>{{ errmsg }}</strong></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Contacts model  -->
<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ action }} User</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form
        (ngSubmit)="saveData()"
        [formGroup]="validationform"
        autocomplete="off"
      >
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            placeholder="Enter name"
            formControlName="name"
            [ngClass]="{ 'is-invalid': submitted && form.name.errors }"
          />
          <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
            <span *ngIf="form.name.errors.required">Name is mandatory.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email address</label>
          <input
            class="form-control"
            id="email"
            placeholder="Enter email"
            formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && form.email.errors }"
          />
          <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
            <span *ngIf="form.email.errors.required"
              >Email address is mandatory.</span
            >
            <span *ngIf="form.email.errors.pattern"
              >Please enter valid email.</span
            >
          </div>
        </div>

        <label>Phone </label>
        <div class="form-group auth-form-group-custom">
          <div class="input-group">
            <ngx-intl-tel-input
              [ngClass]="{
                'is-invalid':
                  submitted &&
                  form.mobileNumber.errors?.invalidPhoneNumber
              }"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.India"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              name="mobileNumber"
              formControlName="mobileNumber"
            >
            </ngx-intl-tel-input>
          </div>
          <div
            *ngIf="submitted && form.mobileNumber.errors"
            class="invalid-feedback"
          >
            <div *ngIf="form.mobileNumber.errors.required">
              MobileNumber is required
            </div>
            <div
              *ngIf="form.mobileNumber.errors.invalidPhoneNumber"
              class="invalid-feedback"
            >
              Invalid phone number
            </div>
          </div>
        </div>

        <div class="form-group" [hidden]="true">
          <label>Phone </label>
          <div>
            <ngx-intl-tel-input
              [ngClass]="{
                'is-invalid': submitted && form.mobileNumber.errors
              }"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.India"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              name="mobile"
              formControlName="mobileNumber"
            >
            </ngx-intl-tel-input>
          </div>
          <div
            *ngIf="submitted && form.mobileNumber.errors"
            class="invalid-feedback"
          >
            <span *ngIf="form.mobileNumber.errors.required"
              >MobileNumber is mandatory.</span
            >
          </div>
        </div>

        <div class="form-group">
          <label>Role </label>
          <select
            type="selected"
            class="form-control"
            formControlName="role"
            [ngClass]="{
              'is-invalid': submitted && form.role.errors
            }"
          >
            <option value="" disabled selected>Select Role</option>
            <option value="SUPERVISOR">SUPERVISOR</option>
            <option value="OPERATOR">OPERATOR</option>
          </select>
          <div *ngIf="submitted && form.role.errors" class="invalid-feedback">
            Role is mandatory.
          </div>
        </div>

        <div class="form-group" *ngIf="action === 'Add'">
          <label>Password </label>
          <div class="input-group input-group-merge form-password-toggle">
            <input
              [type]="fieldTextType ? 'text' : 'password'"
              class="form-control"
              placeholder="Password"
              formControlName="password"
              [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
              (paste)="onPaste($event)"
            />
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer"
                ><span
                  [ngClass]="{
                    'ri-eye-off-fill': !fieldTextType,
                    'ri-eye-fill': fieldTextType
                  }"
                  (click)="toggleFieldTextType()"
                ></span
              ></span>
            </div>
            <div
              *ngIf="submitted && form.password.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.password.errors.required"
                >This value is required.</span
              >
              <span *ngIf="form.password.errors.minlength"
                >Password must be at least 6 characters.</span
              >
            </div>
          </div>
          <div class="mt-2">
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="fieldTextType2 ? 'text' : 'password'"
                class="form-control"
                placeholder="Re-Type Password"
                formControlName="confirmpwd"
                [ngClass]="{
                  'is-invalid': submitted && form.confirmpwd.errors
                }"
                (paste)="onPaste($event)"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><span
                    class="fas field-icon"
                    [ngClass]="{
                      'ri-eye-off-fill': !fieldTextType2,
                      'ri-eye-fill': fieldTextType2
                    }"
                    (click)="toggleFieldTextType2()"
                  ></span
                ></span>
              </div>
              <div
                *ngIf="submitted && form.confirmpwd.errors"
                class="invalid-feedback"
              >
                <span *ngIf="form.confirmpwd.errors.required"
                  >This value is required.</span
                >
                <span *ngIf="form.confirmpwd.errors.mustMatch"
                  >confirm Password should be same as Password.</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">Save</button>
          <button type="button" class="btn btn-danger ml-1" (click)="cancel()">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- Delete model  -->
<ng-template #deletetemp role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Delete User</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="example-container">
      <form>
        <div>
          <p>Are you sure to Delete?</p>
        </div>
        <button
          type="submit"
          class="btn btn-danger round waves-effect headr"
          (click)="delete(delUserId)"
        >
          Delete
        </button>
        &nbsp;
        <button
          type="submit"
          class="btn btn-info round waves-effect headr"
          (click)="modal('Cross click')"
        >
          Cancel
        </button>
      </form>
    </div>
  </div>
</ng-template>
