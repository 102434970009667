import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DashboardComponent } from "./dashboard/dashboard.component";

import { MoreInfoComponent } from "./moreInfo/moreinfo.component";
import { AdminDashboardComponent } from "./admin_dashboard/admin_dashboard.component";
import { ReportsComponent } from "./Reports/reports.component";
import { FirmwareComponent } from "./firmware/firmware.component";
import { EntriesComponent } from "./entries/entries.component";

import { RecordSampleComponent } from "./records/recordsample.component";
import { RecordSampleGuardService } from "../core/guards/deactivate-guard";
import { OperatorDashboardComponent } from "./moreInfo/operator-dashboard/operator-dashboard.component";

const routes: Routes = [
  { path: "", component: DashboardComponent },
  { path: "admin", component: AdminDashboardComponent },
  { path: "operator", component: OperatorDashboardComponent,canDeactivate:[RecordSampleGuardService] },
  { path: "machineInfo", component: MoreInfoComponent },
  { path: "reports", component: ReportsComponent },
  { path: "entries", component: EntriesComponent },
  { path: "firmware", component: FirmwareComponent },
  {
    path: "form",
    loadChildren: () => import("./form/form.module").then((m) => m.FormModule),
  },
  { path: "recordsample", component: RecordSampleComponent, canDeactivate:[RecordSampleGuardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
