import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  FormArray,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrigPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { EventInput } from "@fullcalendar/core";
import * as moment from "moment";
import { DataService } from "src/app/shared/Services/data.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import * as momentTz from "moment-timezone";

@Component({
  selector: "app-production-planning",
  templateUrl: "./production-planning.component.html",
  styleUrls: ["./production-planning.component.scss"],
})
export class ProductionPlanningComponent implements OnInit {
  formData: FormGroup;
  formEditData: FormGroup;
  submitted: boolean;
  category: Event[];
  newEventDate: any;
  editEvent: EventInput;
  deleteEvent: EventInput;
  calendarWeekends: any;
  calendarEvents: EventInput = [];
  today: any;
  endDay: any;
  public IDropdownSettings;
  selectedId: any;
  custId: any;
  isEnable: boolean = false;
  selectedData:any;

  calendarPlugins = [
    dayGridPlugin,
    bootstrapPlugin,
    timeGrigPlugin,
    interactionPlugin,
    listPlugin,
  ];
  lineArr: any = [];
  private subscriptions: Subscription[] = [];
  usersData: any = [];
  partArr: any = [];
  shiftsArr: any = [];
  selectedParts = [];
  partFullArr: any = [];
  disabled = false;
  showFilter = false;
  settings = {};
  action: string;
  minDate: string;
  maxDate: string;
  subParts: any = [];
  selectedDate: any;
  selectedDeviceName: any;
  selectShiftName: any;
  addEvent: any;
  productPlanArr: any = [];
  deviceName: any;
  usersSupervisor: any = [];
  shiftName: any;
  fromDate: any;
  toDate: any;
  targArr: any[];
  target: any = FormArray;
  color: string;
  currentDate:any;
  userData: any;
  currentShiftData: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.currentDate = moment().format("YYYY-MM-DD");
    this.userData = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = this.userData["user"]["custId"];
    this.settings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.today = new Date().toISOString().split("T")[0];
    this.endDay = moment().add(1, "week").format("YYYY-MM-DD");

    this.minDate = moment().format("YYYY-MM-DD");
    this.maxDate = moment(this.minDate)
      .add(7, "day")
      .format("YYYY-MM-DD 06:00:00");
    this.formEditData = this.formBuilder.group({
      editTitle: [],
      editCategory: [],
    });
    this.formInIt();
    this.getLine();
    this.getProductPlanning();
    this._fetchData();
  }

  formInIt() {
    this.formData = this.formBuilder.group({
      date: ["", [Validators.required]],
      custId: [this.custId, [Validators.required]],
      deviceName: ["", [Validators.required]],
      shiftName: ["", [Validators.required]],
      operatorId: ["", [Validators.required]],
      supervisorId: ["", [Validators.required]],
      parts: ["", [Validators.required]],
      target: this.formBuilder.array([]), // [[]],//["", [Validators.required]],
      events: [[]],
    });
  }

  createArr() {
    return this.formBuilder.group({
      partName: [""],
      target: [""],
    });
  }

  get f() {
    return this.formData.get("parts").value;
  }

  getLine() {
    const subscription = this.dataservice.get(`api/device`).subscribe(
      (res: any) => {
        if (res) {
          this.getUser();
          this.lineArr = res;
        }
      },
      (error) => {
        this.toastr.clear();
        if (error == "Server Unreachable")
          this.toastr.error("Server Unreachable", "Error");
        else this.toastr.error(error, "Error");
      }
    );

    this.subscriptions.push(subscription);
  }

  getUser() {
    const subscription = this.dataservice.get("api/user").subscribe(
      (res) => {
        if (res) {
          this.getShift();
          this.usersData = res;
          this.usersSupervisor = res;
          this.usersData = this.usersData.filter((item) => {
            return item["role"] == "OPERATOR";
          });
          this.usersSupervisor = this.usersSupervisor.filter((item) => {
            return item["role"] == "SUPERVISOR";
          });
        } else {
          this.usersData = [];
          this.usersSupervisor = [];
        }
      },
      (error) => {
        this.usersData = [];
        this.toastr.clear();
        this.toastr.error("Server Unreachable", "Error");
      }
    );
    this.subscriptions.push(subscription);
  }

  getShift() {
    const subscription = this.dataservice.get(`api/shift`).subscribe(
      (res: any) => {
        if (res.length) {
          this.getCycleTime();
          this.shiftsArr = res;
          let lastShift = this.shiftsArr[this.shiftsArr.length - 1];
          let endTime = moment(lastShift.endTime, "HH:mm:ss").add(1, "minutes");
          let firstShift = this.shiftsArr[0];
          console.log(firstShift, moment().format("HH:MM:SS"),firstShift < moment().format("HH:MM:SS"),"Comparision")
          let startTime = moment(firstShift.startTime, "DD-MM-YYYY HH:mm:ss");
          if (firstShift.startTime < moment().format("HH:MM:SS")) {
            this.minDate = moment().format("YYYY-MM-DD");
            this.maxDate = moment(this.minDate)
              .add(7, "day")
              .format("YYYY-MM-DD 06:00:00");
          } else {
            this.minDate = moment().subtract(1, "day").format("YYYY-MM-DD");
            this.maxDate = moment(this.minDate)
              .add(7, "day")
              .format("YYYY-MM-DD 06:00:00");
          }
        } else {
          this.shiftsArr = [];
        }
        this.checkToday();
      },
      (error) => {
        this.toastr.clear();
        this.toastr.error("Server Unreachable", "Error");
      }
    );
    this.subscriptions.push(subscription);
  }

  getCycleTime() {
    const subscription = this.dataservice
      .get("programName/masterList/" + this.custId)
      .subscribe(
        (res) => {
          if (res) {
            this.partArr = res['result'];
            this.partFullArr = [];
            this.partArr.forEach((elm, index) => {
              this.partFullArr.push({
                item_id: elm.programId,
                item_text: `${elm.partName}`,
              });
            });
          } else {
            this.toastr.error("You need to configure cycletime data first.");
            // this.partArr = [];
          }
        },
        (error) => {
          this.partArr = [];
          this.toastr.error("Server Unreachable", "Error");
        }
      );
    this.subscriptions.push(subscription);
  }

  checkToday() {
    let timeZone = this.userData["user"]["timeZone"];
    console.log(this.shiftsArr, "shiftObjects");
    this.shiftsArr = this.shiftsArr.map((x, i) => {
      x = JSON.parse(JSON.stringify(x));
      if (i === this.shiftsArr.length - 1) x.isLastShift = true;
      else x.isLastShift = false;
      let endDateTime = undefined,
        startDateTime = undefined;
      const durationTo12 = Math.abs(
        moment
          .duration(
            momentTz
              .tz(
                momentTz.tz(timeZone).format("YYYY-MM-DD") + " 00:00:00",
                timeZone
              )
              .diff(
                momentTz.tz(
                  momentTz.tz(timeZone).subtract(1, "minutes"),
                  timeZone
                )
              )
          )
          .asHours()
      );
      const endTimeDuration = Math.abs(
        moment
          .duration(
            momentTz
              .tz(
                momentTz.tz(timeZone).format("YYYY-MM-DD") + " " + x.endTime,
                timeZone
              )
              .diff(
                momentTz.tz(
                  momentTz.tz(timeZone).format("YYYY-MM-DD") + " 00:00:00",
                  timeZone
                )
              )
          )
          .asHours()
      );
      if (
        x.endNextDayFlag &&
        durationTo12 < endTimeDuration &&
        !x.startNextDayFlag
      ) {
        endDateTime = moment(
          momentTz.tz(timeZone).format("YYYY-MM-DD") + " " + x.endTime
        ).format("YYYY-MM-DDTHH:mm:ss");
        startDateTime = moment(
          momentTz.tz(timeZone).subtract(1, "day").format("YYYY-MM-DD") +
          " " +
          x.startTime
        ).format("YYYY-MM-DDTHH:mm:ss");
        x.isNextday = true;
      } else if (x.endNextDayFlag && !x.startNextDayFlag) {
        endDateTime = moment(
          momentTz.tz(timeZone).add(1, "day").format("YYYY-MM-DD") +
          " " +
          x.endTime
        ).format("YYYY-MM-DDTHH:mm:ss");
      } else if (x.startNextDayFlag) {
        x.isNextday = true;
      }
      x.startDateTime =
        startDateTime ||
        moment(
          momentTz.tz(timeZone).format("YYYY-MM-DD") + " " + x.startTime
        ).format("YYYY-MM-DDTHH:mm:ss");
      x.epochShiftStart = moment.tz(x.startDateTime, timeZone).valueOf();
      x.endDateTime =
        endDateTime ||
        moment(
          momentTz.tz(timeZone).format("YYYY-MM-DD") + " " + x.endTime
        ).format("YYYY-MM-DDTHH:mm:ss");
      x.epochShiftEnd = moment.tz(x.endDateTime, timeZone).valueOf();
      return x;
    });
    let currentshiftDate = momentTz.tz(timeZone).format("YYYY-MM-DDTHH:mm:ss");
    let currentShift = {};
    for (const shift of this.shiftsArr) {
      const isCurrentShift = moment(currentshiftDate).isBetween(
        moment(shift.startDateTime),
        moment(shift.endDateTime)
      );
      if (isCurrentShift) {
        if (shift.isNextday) {
          shift["currentDate"] =
            momentTz.tz(timeZone).subtract(1, "days").format("YYYY-MM-DD") +
            " 00:00:00";
        } else {
          shift["currentDate"] =
            momentTz.tz(timeZone).format("YYYY-MM-DD") + " 00:00:00";
        }
        shift["currentDateTime"] = currentshiftDate;
        currentShift = shift;
        break;
      }
    }
    console.log(currentShift, "currentShift");
    this.currentShiftData = currentShift;
  }

  funDate(date, shift) {
    console.log(date,"ahsgdf", shift, "sd",this.currentDate,this.shiftsArr);
    if (moment(date).isBefore(this.currentDate)) {
      let currentFlag = false;

      console.log(this.shiftsArr,shift,this.currentShiftData["usershiftId"],"currrentshift")
      if (shift == this.currentShiftData["usershiftId"]) {
        return false;
      } else {
        let s = this.shiftsArr.some((val) => {
          if (val.usershiftId == this.currentShiftData["usershiftId"]) {
            currentFlag = true;
          }
          if (val.usershiftId == shift) {
            return currentFlag ? false : true;
          }
        });
        return s;
      }
      // return true;
    } else if (moment(date).isSame(this.currentDate)) {
      // let currentFlag = false;

      // console.log(this.shiftsArr,shift,this.currentShiftData["usershiftId"],"currrentshift")
      // if (shift == this.currentShiftData["usershiftId"]) {
      //   return false;
      // } else {
      //   let s = this.shiftsArr.some((val) => {
      //     if (val.usershiftId == this.currentShiftData["usershiftId"]) {
      //       currentFlag = true;
      //     }
      //     if (val.usershiftId == shift) {
      //       return currentFlag ? false : true;
      //     }
      //   });
      //   return s;
      // }
       return false;
    } else if (moment(date).isAfter(this.currentDate)) {
      return false;
    } else {
      return false;
    }
  }

  getProductPlanning() {
    const subscription = this.dataservice
      .get("mapping/programMapping")
      .subscribe(
        (res) => {
          if (res) {
            this.calendarEvents = [];
            this.productPlanArr = res;
            this.productPlanArr.forEach((elm) => {
              if (elm.events.length) {
                this.calendarEvents.push(...elm.events);
              }
            });
          }
        },
        (error) => {
          this.productPlanArr = [];

          this.toastr.error("Server Unreachable", "Error");
        }
      );
    this.subscriptions.push(subscription);
  }

  getRandomColor() {
    const letters = "0123456789ABCDEF";
    this.color = "#";
    for (let i = 0; i < 6; i++) {
      this.color += letters[Math.floor(Math.random() * 16)];
    }
    return this.color;
  }

  onItemDeSelect(event) {
    const targetFormArray = this.formData.get("target") as FormArray;
    const indexToRemove = targetFormArray.controls.findIndex(
      (control) => control.value["partName"] === event["item_text"]
    );
    if (indexToRemove !== -1) {
      targetFormArray.removeAt(indexToRemove);
    }
  }

  onItemSelect(event) {
    this.patchFormArrayValues("target", [event]);
  }
  onSelectAll(event) {
    const targetFormArray = this.formData.get("target") as FormArray;
    targetFormArray.clear();
    this.patchFormArrayValues("target", event);
  }
  onDeSelectAll(event) {
    const targetFormArray = this.formData.get("target") as FormArray;
    targetFormArray.clear();
  }

  change(data: any, index: any) {
    this.formData.get("target").value[index] = {
      partName: this.formData.get("parts").value[index].item_text,
      target: Number(data.target.value),
    };
    this.formData.get("parts").value[index]["target"] = Number(
      data.target.value
    );
  }

  lineChange(data) {
    // this.partFullArr = [];
    this.partArr.forEach((elm) => {
      if (elm["deviceName"] === data) {
        this.partFullArr.push({
          item_id: elm.programId,
          item_text: elm.partName,
        });
      }
    });
    this.lineArr.forEach((elm) => {
      if (elm["deviceName"] == data) {
        this.deviceName = elm.deviceName;
      }
    });
  }

  shiftChange(data) {
    this.shiftsArr.forEach((elm) => {
      if (data.target.value == elm.shiftName) {
      
        this.isEnable = this.funDate(this.selectedData, elm["usershiftId"]);
    
        this.shiftName = elm.shiftName;
        if (elm.endNextDayFlag) {
          this.fromDate = moment(
            `${this.newEventDate} ${elm.startTime}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          this.toDate = moment(
            `${this.newEventDate} ${elm.endTime}`,
            "YYYY-MM-DD HH:mm:ss"
          )
            .add(1, "day")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.fromDate = moment(
            `${this.newEventDate} ${elm.startTime}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          this.toDate = moment(
            `${this.newEventDate} ${elm.endTime}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        console.log(elm, this.fromDate, this.toDate);
      }
    });
  }

  openModal(content: any, event: any) {
    console.log(event,"addopenModel")
    this.formInIt();
    this.action = "Add";
    this.addEvent = event;
    this.selectedData = event.dateStr
    this.newEventDate = moment(event.date).format("YYYY-MM-DD");
    this.formData.get("date").setValue(`${this.newEventDate}`);
    // this.shiftsArr.forEach((elm) => {
    //   if (event.event._def.extendedProps.shiftName == elm.shiftName) {
    //     this.isEnable = this.funDate(event.dateStr, elm["usershiftId"]);
    //   }
    // })
    this.modalService.open(content, { backdrop: "static", centered: true });
  }

  openEditModal(editcontent: any, event: any) {
    console.log(event,"event")
    this.action = "Edit";
    let tempData = {};
    this.shiftsArr.forEach((elm) => {
      if (event.event._def.extendedProps.shiftName == elm.shiftName) {
        this.isEnable = this.funDate(event.event._def.extendedProps.eventEate, elm["usershiftId"]);
        this.shiftName = elm.name;
        if (elm.endNextDayFlag) {
          this.fromDate = moment(
            `${event.event._def.extendedProps.eventEate} ${elm.startTime}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          this.toDate = moment(
            `${event.event._def.extendedProps.eventEate} ${elm.endTime}`,
            "YYYY-MM-DD HH:mm:ss"
          )
            .add(1, "day")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.fromDate = moment(
            `${event.event._def.extendedProps.eventEate} ${elm.startTime}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          this.toDate = moment(
            `${event.event._def.extendedProps.eventEate} ${elm.endTime}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
        }
      }
    });
    this.targArr = [];
    this.productPlanArr.forEach((elm) => {
      if (
        elm.shiftName == event.event._def.extendedProps.shiftName &&
        moment(elm.date).format("YYYY-MM-DD") ==
          event.event._def.extendedProps.eventEate &&
        elm.deviceName == event.event._def.extendedProps.deviceName
      ) {
        elm["date"] = moment(elm["date"]).format("YYYY-MM-DD");
        tempData = elm;
      }
    });
    // this.lineChange(tempData["deviceName"]);
    this.formInIt();
    this.pathValue(this.formData, tempData);
    this.patchFormArrayValues("target", tempData["target"]);
    this.selectedDate = tempData["date"];
    this.selectedDeviceName = tempData["deviceName"];
    this.selectShiftName = tempData["shiftName"];
    this.targArr = tempData["target"];
    this.selectedId = tempData["id"];
    this.formData.get("deviceName").disable();
    this.formData.get("shiftName").disable();
    this.formData.get("operatorId").disable();
    this.formData.get("supervisorId").disable();
    this.modalService.open(editcontent, { backdrop: "static", centered: true });
  }

  patchFormArrayValues(arrayName: string, values: any) {
    const formArray = this.formData.get(arrayName) as FormArray;
    values.forEach((item) => {
      formArray.push(
        this.formBuilder.group({
          partName: new FormControl({
            value: item.partName
              ? item.partName
              : item.item_text
              ? item.item_text
              : "",
            disabled: this.action == "Add" ? false : false,
          }),
          target: new FormControl(item.target ? item.target : 0),
        })
      );
    });
  }

  get targetFormArray() {
    return (this.formData.get("target") as FormArray).controls;
  }

  get form() {
    return this.formData.controls;
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      if (key != "target") {
        controls[key].patchValue(data[key]);
      }
    }
  }

  deleteEventData() {
    this.formData = this.formData;
    const subscription = this.dataservice
      .delete(`mapping/programMapping/${this.selectedId}`)
      .subscribe(
        (res) => {
          if (res["message"]) this.toastr.success(res["message"], "Success");
          else this.toastr.success("Planning Deleted Successfully", "Success");

          this.getProductPlanning();
          this.close();
        },
        (error) => {
          this.formData.get("deviceName").enable();
          this.formData.get("shiftName").enable();
          this.formData.get("operatorId").enable();
          this.formData.get("supervisorId").enable();
          if (error == "Server Unreachable")
            this.toastr.error("Server Unreachable", "Error");
          else this.toastr.error(error, "Error");
        }
      );
    this.subscriptions.push(subscription);
    this.modalService.dismissAll();
  }

  saveEvent() {
    this.submitted = true;
    if (this.formData.invalid) {
      return this.formData.controls;
    } else {
      if (this.action == "Add") {
        const subscription = this.dataservice
          .post(`mapping/programMapping`, this.formData.value)
          .subscribe(
            (res) => {
              if (res["message"]) {
                this.toastr.success(res["message"], "Success");
              } else
                this.toastr.success("Planning Added Successfully", "Success");

              this.getProductPlanning();
              this.close();
            },
            (error) => {
              if (error == "Server Unreachable") {
                this.toastr.error("Server Unreachable", "Error");
              } else if (error == "PartMapping Already Exists!!") {
                this.toastr.warning(error,"Error");
              } else {
                this.toastr.error(error, "Error");
              }
            }
          );
        this.subscriptions.push(subscription);
      } else if (this.action == "Edit") {
        this.formData.get("deviceName").enable();
        this.formData.get("shiftName").enable();
        this.formData.get("operatorId").enable();
        this.formData.get("supervisorId").enable();
        const subscription = this.dataservice
          .put(`mapping/programMapping/${this.selectedId}`, this.formData.value)
          .subscribe(
            (res) => {
              if (res["message"]) {
                this.toastr.success(res["message"], "Success");
                this.formData.get("deviceName").disable();
                this.formData.get("shiftName").disable();
                this.formData.get("operatorId").disable();
                this.formData.get("supervisorId").disable();
              } else
                this.toastr.success("Planning Updated Successfully", "Success");

              this.getProductPlanning();
              this.close();
            },
            (error) => {
              this.formData.get("deviceName").disable();
              this.formData.get("shiftName").disable();
              this.formData.get("operatorId").disable();
              this.formData.get("supervisorId").disable();
              if (error == "Server Unreachable")
                this.toastr.error("Server Unreachable", "Error");
              else this.toastr.error(error, "Error");
            }
          );
        this.subscriptions.push(subscription);
      }
    }
  }

  addEventData() {
    if (this.formData.valid) {
      let startTime,
        endTime = "";
      this.shiftsArr.forEach((elm) => {
        if (elm.shiftName == this.formData.value.shiftName) {
          startTime = elm.startTime;
          endTime = elm.endTime;
        }
      });
      startTime = moment(`${this.formData.value.date} ${startTime}`).format();
      endTime = moment(`${this.formData.value.date} ${endTime}`).format();
      this.formData.get("events").value[0] = {
        title: this.deviceName + "_" + this.shiftName,
        start: startTime,
        end: endTime,
        backgroundColor: this.getRandomColor(), //"blue",
        borderColor: this.color, //"blue",
        eventEate: this.formData.value.date,
        shiftName: this.formData.value.shiftName,
        deviceName: this.formData.value.deviceName,
      };
      this.saveEvent();
    } else {
      this.toastr.info("Please fill all the fields", "Info");
    }
  }

  confirm() {}

  private _fetchData() {
    this.submitted = false;
  }

  close() {
    this.modalService.dismissAll();
    this.formInIt();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
