import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DeviceComponent } from "./deviceList/deviceadd.component";
import { UserComponent } from "./users/user.component";
import { ReasonsComponent } from "./reasons/reasons.component";
import { CustomerInfoComponent } from "./customer_info/customer_info.component";
import { ShiftInfoComponent } from "./shift_info/shift_info.component";
import { ProductionPlanningComponent } from "./production-planning/production-planning.component";
import { ProgramListComponent } from "./program-list/program-list.component";

const routes: Routes = [
  {
    path: "reasons",
    component: ReasonsComponent,
  },
  {
    path: "user",
    component: UserComponent,
  },
  {
    path: "device",
    component: DeviceComponent,
  },

  {
    path: "shift",
    component: ShiftInfoComponent,
  },
  {
    path: "customer",
    component: CustomerInfoComponent,
  },
  {
    path: "programList",
    component: ProgramListComponent,
  },
  {
    path: "productionPlanning",
    component:ProductionPlanningComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormRoutingModule {}
