import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiModule } from "../shared/ui/ui.module";
import { WidgetModule } from "../shared/widget/widget.module";
import { PagesRoutingModule } from "./pages-routing.module";
import {
  NgbNavModule,
  NgbDropdownModule,
  NgbTooltipModule,
  NgbToastModule,
  NgbModule,
  NgbDatepickerModule,
  NgbCollapseModule,
} from "@ng-bootstrap/ng-bootstrap";
// import { NgApexchartsModule } from "ng-apexcharts";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FullCalendarModule } from "@fullcalendar/angular";
import { DndModule } from "ngx-drag-drop";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { NgSelectModule } from "@ng-select/ng-select";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FormModule } from "./form/form.module";
import { MoreInfoComponent } from "./moreInfo/moreinfo.component";
import { NgxEchartsModule } from "ngx-echarts";
import * as echarts from "echarts";
import { GanttchartComponent } from "./moreInfo/ganttChart/ganttchart.component";
import { CombinechartComponent } from "./combineChart/combinechart.component";
import { AdminDashboardComponent } from "./admin_dashboard/admin_dashboard.component";
import { ReportsComponent } from "./Reports/reports.component";
import { BarchartComponent } from "./Reports/barchart/barchart.component";
import { FirmwareComponent } from "./firmware/firmware.component";
import { EntriesComponent } from "./entries/entries.component";
import { StatusBarchartComponent } from "./Reports/statusbar/statusbarchart.component";
import { ScatterChartComponent } from "./moreInfo/scatter-chart/scatter-chart.component";
import { FixedDecimalPipe } from "../services/fixedDecimal.pipe";
import { RecordSampleComponent } from "./records/recordsample.component";
import { GraphViewComponent } from "./dashboard/graphview/graphview.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { EnergyChartComponent } from "./moreInfo/energyChart/energyChart.component";
// import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxSpinnerModule } from "ngx-spinner";
import { NewganttchartComponent } from "./moreInfo/newGanttChart/newganttchart.component";
import { PartcountchartComponent } from "./moreInfo/hourlyPartChart/partcountchart.component";
import { OperatorDashboardComponent } from './moreInfo/operator-dashboard/operator-dashboard.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3,
};

@NgModule({
  declarations: [
    DashboardComponent,
    AdminDashboardComponent,
    MoreInfoComponent,
    GanttchartComponent,
    EnergyChartComponent,
    CombinechartComponent,
    ReportsComponent,
    StatusBarchartComponent,
    BarchartComponent,
    FirmwareComponent,
    EntriesComponent,
    ScatterChartComponent,
    FixedDecimalPipe,
    RecordSampleComponent,
    GraphViewComponent,
    NewganttchartComponent,
    PartcountchartComponent,
    OperatorDashboardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    NgbModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgMultiSelectDropDownModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbDatepickerModule,
    UiSwitchModule,
    NgbCollapseModule,
    // NgApexchartsModule,
    NgSelectModule,
    PerfectScrollbarModule,
    DndModule,
    FullCalendarModule,
    FormModule,
    LeafletModule,
    WidgetModule,
    NgxSpinnerModule,
    // NgMultiSelectDropDownModule.forRoot(),

    NgxEchartsModule.forRoot({
      // echarts,
      // echarts: () => import("echarts"),
      echarts: { init: echarts.init },
    }),
    // NgApexchartsModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class PagesModule {}
