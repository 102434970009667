import { MenuItem } from "./menu.model";

export const CUSTOMER: MenuItem[] = [
  {
    id: 1,
    label: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    id: 2,
    label: "Reports",
    icon: "ri-profile-line",
    link: "/reports",
  },

  {
    id: 3,
    label: "Configuration",
    icon: "ri-settings-5-line",
    subItems: [
      {
        id: 4,
        label: "User",
        link: "/user",
        parentId: 2,
      },
      {
        id: 5,
        label: "Machine",
        link: "/device",
        parentId: 3,
      },
      {
        id: 6,
        label: "Shift",
        link: "/shift",
        parentId: 3,
      },
      {
        id: 7,
        label: "Reason List",
        link: "/reasons",
        parentId: 3,
      },
      {
        id: 8,
        label: "Program List",
        link: "/programList",
        parentId: 3,
      },
      {
        id: 9,
        label: "Production Planning",
        link: "/productionPlanning",
        parentId: 3,
      }
    ],
  },
  // {

  //   id: 8,
  //   label: "Entries",
  //   icon: "ri-edit-box-fill",
  //   link: "/entries",
  // },
  // {
  //   id: 9,
  //   label: 'Sample',
  //   icon: "ri-settings-5-line",
  //   link: "/sample",
  // },
];
