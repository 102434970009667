<!-- <div class="container-fluid">
  <app-pagetitle title="Reasons" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div *ngIf="errmsg == ''">
            <a class="btn btn-success mb-2" (click)="openModal(content, '', 'Add')">
              <i class="mdi mdi-plus mr-2"></i> Add Reasons
            </a>
          </div>
          <div class="table-responsive mt-3" *ngIf="configuredReasons.length != 0">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%">
              <thead class="thead-light">
                <tr>
                  <th>Reason Code</th>
                  <th>Reason Type</th>
                  <th>Reason</th>
                  <th style="width: 120px">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="
              let item of configuredReasons
                | slice : pageSize
                | slice : 0 : itemsPerPage;
              let i = index
            ">
                <tr>
                  <td>{{ item?.reasonCode }}</td>
                  <td>{{ item?.reasonType }}</td>
                  <td>{{ item?.reason }}</td>
                  <td>
                    <a (click)="openModal(content, item, 'Edit')" class="mr-3 text-primary" v-b-tooltip.hover
                      title="Edit" style="cursor: pointer">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a class="text-danger" v-b-tooltip.hover title="Delete" style="cursor: pointer"
                      (click)="openModalDelete(deletetemp, item)">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="configuredReasons?.length > 5">
              <ngb-pagination class="pagination-rounded" [collectionSize]="configuredReasons?.length" #numPages
                [pageSize]="itemsPerPage" [(page)]="currentPage" [maxSize]="1" [rotate]="true" [ellipses]="false"
                [boundaryLinks]="true" (pageChange)="onPageChange(currentPage)">
              </ngb-pagination>
            </div>
          </div>
          <div class="card" *ngIf="configuredReasons.length == 0">
            <span *ngIf="errmsg == ''"><strong>No Reasons Found. Please Add Reasons</strong></span>
            <span *ngIf="errmsg != ''"><strong>{{errmsg}}</strong></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ action }} Reason</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="reasonform" autocomplete="off">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="reasonCode">Reason Code</label>
            <input type="text" class="form-control" id="reasonCode" placeholder="Enter reason code"
              formControlName="reasonCode" [ngClass]="{ 'is-invalid': submitted && form.reasonCode.errors }" />
            <div *ngIf="submitted && form.reasonCode.errors" class="invalid-feedback">
              <span *ngIf="form.reasonCode.errors.required">Reason Code is mandatory.</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="reasonType">Reason Type</label>
            <select type="selected" class="form-control" formControlName="reasonType" [ngClass]="{
            'is-invalid': submitted && form.reasonType.errors
          }">
              <option value="" disabled selected>
                Select reason Type
              </option>
              <option value="downtime">Downtime</option>
              <option value="rejection">Rejection</option>
            </select>
            <div *ngIf="submitted && form.reasonType.errors" class="invalid-feedback">
              ReasonType is mandatory.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="reason">Reason</label>
            <input class="form-control" id="reason" placeholder="Enter Reason" formControlName="reason"
              [ngClass]="{ 'is-invalid': submitted && form.reason.errors }" />
            <div *ngIf="submitted && form.reason.errors" class="invalid-feedback">
              <span *ngIf="form.reason.errors.required">Reason is mandatory.</span>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">Save</button>
          <button type="button" class="btn btn-danger ml-1" (click)="cancel()">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>


<ng-template #deletetemp role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Delete Reason</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="example-container">
      <form>
        <div>
          <p>Are you sure to Delete?</p>
        </div>
        <button type="submit" class="btn btn-danger round waves-effect headr" (click)="delete(delreasonId)">
          Delete
        </button>
        &nbsp;
        <button type="submit" class="btn btn-info round waves-effect headr" (click)="modal('Cross click')">
          Cancel
        </button>
      </form>
    </div>
  </div>
</ng-template> -->

<div class="container-fluid">
  <app-pagetitle title="Reasons" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between" *ngIf="errmsg == ''">
            <a
              class="btn btn-success mb-2"
              (click)="openModal(content, '', 'Add')"
            >
              <span> <i class="mdi mdi-plus mr-2"></i> Add Reason</span>
            </a>
            <div
              class="d-flex align-items-center"
              *ngIf="configuredReasons?.length > 0"
            >
              <select
                class="form-control"
                name="select"
                id="select"
                [(ngModel)]="selectedType"
                (change)="selectType($event.target.value)"
                style="color: black"
              >
                <option value="" disabled>Select ReasonType</option>
                <option
                  [value]="data.value"
                  *ngFor="let data of reasonTypeData"
                >
                  {{ data.reasonType }}
                </option>
              </select>
              <!-- <select
                *ngIf="isCatagery"
                class="form-control"
                name="select"
                id="select"
                [(ngModel)]="selectedCatagory"
                (change)="selectType($event.target.value)"
                style="color: black" 
              >
                <option value="" disabled>Select ReasonType</option>
                <option
                  [value]="data.value"
                  *ngFor="let data of categoryTypeData"
                >
                  {{ data.reasonType }}
                </option>
              </select> -->

              <input
                type="text"
                class="form-control ml-2"
                placeholder="Search..."
                [(ngModel)]="searchText"
              />
            </div>
          </div>
          <div
            class="table-responsive mt-3"
            *ngIf="configuredReasons1?.length != 0"
          >
            <table
              class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%"
            >
              <thead class="thead-light bold-header">
                <tr>
                  <th>Reason Code</th>
                  <!-- <th>Reason Type</th> -->
                  <th>Reason</th>
                  <th
                    *ngIf="
                    
                      selectedType == 'downtime'
                    "
                  >
                    Sub-Reason
                  </th>
                  <th style="width: 120px">Action</th>
                </tr>
              </thead>
              <tbody
                *ngFor="
                  let item of configuredReasons1
                    | slice : pageSize
                    | slice : 0 : itemsPerPage
                    | filter : searchText;
                  let i = index
                "
              >
                <tr>
                  <td>{{ item?.reasonCode }}</td>
                  <!-- <td>{{ item?.reasonType }}</td> -->
                  <td>{{ item?.reason }}</td>
                  <td
                    *ngIf="
                    selectedType=='downtime'
                    "
                  >
                    <i
                      class="mdi mdi-eye cursor-pointer"
                      (click)="openModalDelete(SubReason, item)"
                      *ngIf="item?.subReason.length > 0"
                    ></i>
                    <h6 *ngIf="item?.subReason.length == 0">---</h6>
                  </td>
                  <td>
                    <a
                      (click)="openModal(content, item, 'Edit')"
                      class="mr-3 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      style="cursor: pointer"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      style="cursor: pointer"
                      (click)="openModalDelete(deletetemp, item)"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <div class="col-md-6" *ngIf="configuredReasons?.length > 5">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="configuredReasons?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
          <div class="card" *ngIf="configuredReasons1?.length == 0">
            <div class="row justify-content-center">
              <span *ngIf="errmsg == ''"
                ><strong>No Reasons Found. Add Reasons to view</strong></span
              >
              <span *ngIf="errmsg != ''"
                ><strong>{{ errmsg }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reaons model  -->
<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ action }} Reason</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="reasonform" autocomplete="off">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="reasonCode">Reason Code</label>
            <input
              type="text"
              class="form-control"
              id="reasonCode"
              placeholder="Enter reason code"
              formControlName="reasonCode"
              [ngClass]="{ 'is-invalid': submitted && form.reasonCode.errors }"
              [title]="reasonform.get('reasonCode')?.disabled ? 'Field is disabled' : ''"
            />
            <div
              *ngIf="submitted && form.reasonCode.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.reasonCode.errors.required"
                >Reason Code is mandatory.</span
              >
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="reasonType">Reason Type</label>
            <select
              type="selected"
              class="form-control"
              formControlName="reasonType"
              (change)="configTypeChange($event)"
              [ngClass]="{
                'is-invalid': submitted && form.reasonType.errors
              }"
              [title]="reasonform.get('reasonType')?.disabled ? 'Field is disabled' : ''"
              style="color: black"
            >
              <option value="" disabled selected>Select reason Type</option>
              <option value="downtime">Downtime</option>
              <option value="rejection">Rejection</option>
            </select>
            <div
              *ngIf="submitted && form.reasonType.errors"
              class="invalid-feedback"
            >
              ReasonType is mandatory.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="reason">Reason</label>
            <input
              class="form-control"
              id="reason"
              placeholder="Enter Reason"
              formControlName="reason"
              [ngClass]="{ 'is-invalid': submitted && form.reason.errors }"
            />
            <div
              *ngIf="submitted && form.reason.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.reason.errors.required"
                >Reason is mandatory.</span
              >
            </div>
          </div>
        </div>

        <div class="row" *ngIf="hideSubReason">
          <div class="form-group col-md-12">
            <label for="subReason" (click)="subReasonCheck($event)">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                formControlName="issubReason"
                [checked]="issubReason"
              />
              &nbsp; Includes sub-reasons
            </label>
          </div>
        </div>

        <div class="row" *ngIf="issubReason && hideSubReason">
          <div class="form-group col-md-12">
            <div class="row justify-content-end mt-2" (click)="AddSubReason()">
              <i class="mdi mdi-plus-circle mr-2" style="color: '#1bcfb4'"></i>
            </div>
          </div>
          <table class="responsive ml-2 col-md-10">
            <thead
              class="bold-header"
              *ngIf="
                reasonform.get('subReason').value &&
                reasonform.get('subReason').value.length > 0
              "
            >
              <tr>
                <td>Sub-Reason Code</td>
                <td>Sub-Reason</td>
              </tr>
            </thead>
            <tbody
              formArrayName="subReason"
              *ngFor="
                let item of reasonform.get('subReason')['controls'];
                let i = index
              "
            >
              <tr [formGroupName]="i">
                <td>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="subReasonArr1"
                      formControlName="subReasonCode"
                      placeholder="R0_001"
                    />
                    <!-- (change)="subReasonAdding($event, 'subReasonCode')" -->
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="subReasonArr2"
                      formControlName="subReason"
                      placeholder="Enter Sub-Reason"
                    />
                    <!-- (change)="subReasonAdding($event, 'subReason')" -->
                  </div>
                </td>
                <td>
                  <div class="form-group mt-2" (click)="delSubReason(i)">
                    <i class="mdi mdi-minus-circle mr-1"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">Save</button>
          <button type="button" class="btn btn-danger ml-1" (click)="cancel()">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #SubReason role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Sub Reasons</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="table-responsive">
      <table
        class="table table-striped table-bordered table-sm"
        cellspacing="0"
        width="100%"
      >
        <thead class="bold-header" style="background-color: #c8d0d8">
          <tr>
            <th>Sub Reason Code</th>
            <th>Sub-Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let el of subReason; let i = index">
            <td>{{ el.subReasonCode }}</td>
            <td>{{ el.subReason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<!-- Delete model  -->
<ng-template #deletetemp role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Delete Reason</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="example-container">
      <form>
        <div>
          <p>Are you sure to Delete?</p>
        </div>
        <button
          type="submit"
          class="btn btn-danger round waves-effect headr"
          (click)="delete(delreasonId)"
        >
          Delete
        </button>
        &nbsp;
        <button
          type="submit"
          class="btn btn-info round waves-effect headr"
          (click)="modal('Cross click')"
        >
          Cancel
        </button>
      </form>
    </div>
  </div>
</ng-template>
