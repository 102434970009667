<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="app-calendar">
          <!-- calendar -->
          <div class="card">
            <div class="card-body">
              <full-calendar
                #calendar
                defaultView="dayGridMonth"
                [header]="{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek'
                }"
                [plugins]="calendarPlugins"
                [eventResizableFromStart]="'true'"
                [eventLimit]="true"
                [editable]="true"
                themeSystem="bootstrap"
                [weekends]="calendarWeekends"
                [bootstrapFontAwesome]="false"
                [validRange]="{ start: minDate, end: maxDate }"
                deepChangeDetection="true"
                [events]="calendarEvents"
                (dateClick)="openModal(content, $event)"
                (eventClick)="openEditModal(editcontent, $event)"
              >
              </full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Event Modal -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Add New Event</h5>
      <button type="button" class="close" aria-hidden="true" (click)="close()">
        ×
      </button>
    </div>
    <div class="modal-body p-3">
      <form (ngSubmit)="addEventData()" [formGroup]="formData">
        <div class="row">
          <div class="col-md-12 d-flex">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Select Machine</label>
                <select
                  class="form-control"
                  name="deviceName"
                  id="deviceName"
                  formControlName="deviceName"
                  (change)="lineChange($event.target.value)"
                  style="color: #000"
                >
                  <option value="" selected >Select Machine</option>
                  <option [value]="line.deviceName" *ngFor="let line of lineArr">
                    {{ line.deviceName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <label class="control-label">Select Shift</label>
              <select
                class="form-control"
                name="shift"
                id="shift"
                formControlName="shiftName"
                (change)="shiftChange($event)"
                style="color: #000"
              >
                <option value="" selected >Select Shift</option>
  
                <option [value]="shift.shiftName" *ngFor="let shift of shiftsArr">
                  {{ shift.shiftName }}
                </option>
              </select>
            </div>
          </div>
  
          <div class="col-md-12 d-flex">
            <div class="col-md-6">
              <label class="control-label">Map Supervisor</label>
              <select
                class="form-control"
                name="operator"
                id="operator"
                formControlName="supervisorId"
                style="color: #000"
              >
                <option value="" selected >Select Supervisor</option>
                <option
                  [value]="user.name"
                  *ngFor="let user of usersSupervisor"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="control-label">Map Operator</label>
              <select
                class="form-control"
                name="operator"
                id="operator"
                formControlName="operatorId"
                style="color: #000"
              >
                <option value="" selected >Select Operator</option>
                <option [value]="user.name" *ngFor="let user of usersData">
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
  
          <div class="col-md-12 mt-3">
            <div class="col-md-12">
              <label class="control-label">Map Parts</label>
              <ng-multiselect-dropdown
                [placeholder]="'Select Parts'"
                [settings]="settings"
                [data]="partFullArr"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                name="domain"
                formControlName="parts"
                style="color: #000"
              ></ng-multiselect-dropdown>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="col-md-12">
              <label>Set Part-wise Target</label>
              <div formArrayName="target">
                <div
                  *ngFor="let group of targetFormArray; let i = index"
                  [formGroupName]="i"
                  class="form-group"
                >
                  <div class="col-md-12">
                    <input
                      type="text"
                      formControlName="partName"
                      class="form-control"
                      placeholder="Part Name"
                      style="color: black"
                      
                    />
                  </div>
                  <div class="col-md-12">
                    <input
                      type="number"
                      formControlName="target"
                      class="form-control"
                      placeholder="Target"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="text-right pt-4">
          <button type="button" class="btn btn-light" (click)="close()">
            Close
          </button>
          <button [disabled]="isEnable" type="submit" class="btn btn-success save-event ml-1">
            Save
          </button>
        </div>
      </form>
    </div>
  </ng-template>
  
  <!-- Edit event modal -->
  <ng-template #editcontent let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Edit Event</h5>
      <button
        type="button"
        class="close"
        aria-hidden="true"
        (click)="modal.dismiss('Cross click')"
      >
        ×
      </button>
    </div>
  
    <div class="modal-body p-3">
      <form (ngSubmit)="saveEvent()" [formGroup]="formData">
        <div class="row">
          <div class="col-md-12 d-flex">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Select Machine</label>
                <select
                  class="form-control"
                  name="deviceName"
                  id="deviceName"
                  formControlName="deviceName"
                  (change)="lineChange($event)"
                  style="color: #000"
                >
                  <option value="" selected >Select Machine</option>
                  <option [value]="line.deviceName" *ngFor="let line of lineArr">
                    {{ line.deviceName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <label class="control-label">Select Shift</label>
              <select
                class="form-control"
                name="shift"
                id="shift"
                formControlName="shiftName"
                (change)="shiftChange($event)"
                style="color: #000"
              >
                <option value="" selected >Select Shift</option>
  
                <option [value]="shift.shiftName" *ngFor="let shift of shiftsArr">
                  {{ shift.shiftName }}
                </option>
              </select>
            </div>
          </div>
  
          <div class="col-md-12 d-flex">
            <div class="col-md-6">
              <label class="control-label">Map Supervisor</label>
              <select
                class="form-control"
                name="operator"
                id="operator"
                formControlName="supervisorId"
                style="color: #000"
              >
                <option value="" selected >Select Supervisor</option>
                <option
                  [value]="user.name"
                  *ngFor="let user of usersSupervisor"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="control-label">Map Operator</label>
              <select
                class="form-control"
                name="operator"
                id="operator"
                formControlName="operatorId"
                style="color: #000"
              >
                <option value="" selected >Select Operator</option>
                <option [value]="user.name" *ngFor="let user of usersData">
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
  
          <div class="col-md-12 mt-3">
            <div class="col-md-12">
              <label class="control-label">Map Parts</label>
              <ng-multiselect-dropdown
                [placeholder]="'Select Parts'"
                [settings]="settings"
                [data]="partFullArr"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                name="domain"
                formControlName="parts"
                style="color: #000"
              ></ng-multiselect-dropdown>
            </div>
          </div>
  
          <div class="col-md-12 mt-3">
            <div class="col-md-12">
              <label>Set Part-wise Target</label>
              <div formArrayName="target">
                <div
                  *ngFor="let group of targetFormArray; let i = index"
                  [formGroupName]="i"
                  class="form-group"
                >
                  <div class="col-md-12">
                    <input
                      type="text"
                      formControlName="partName"
                      class="form-control"
                      placeholder="Part Name"
                      style="color: black"
                      
                    />
                  </div>
                  <div class="col-md-12">
                    <input
                      type="number"
                      formControlName="target"
                      class="form-control"
                      placeholder="Target"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  
    <div class="text-right p-3">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.dismiss('Close')"
      >
        Close
      </button>
      <button
      [disabled]="isEnable"
        type="button"
        class="btn btn-danger delete-event ml-1"
        (click)="deleteEventData()"
      >
        Delete
      </button>
      <button [disabled]="isEnable" type="button" class="btn btn-success ml-1" (click)="saveEvent()">
        Update
      </button>
    </div>
  </ng-template>
  