import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { DataService } from "src/app/shared/Services/data.service";

@Component({
  selector: "app-reasons",
  templateUrl: "./reasons.component.html",
  styleUrls: ["./reasons.component.scss"],
})
export class ReasonsComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;
  // configuredReasons: any = [];
  // reasonform: FormGroup;
  // errmsg: string = "";
  // delreasonId = "";
  // currentPage = 1;
  // itemsPerPage = 5;
  // pageSize;
  // action: any;
  // editReasonId;
  // submitted: boolean = false;

  // constructor(
  //   private modalService: NgbModal,
  //   public formBuilder: FormBuilder,
  //   private dataservice: DataService,
  //   private toastr: ToastrService
  // ) {}

  // ngOnInit() {
  //   this.breadCrumbItems = [
  //     { label: "Configuration" },
  //     { label: "Reasons", active: true },
  //   ];
  //   this.getreasons();
  // }

  // getreasons() {
  //   this.dataservice.get("api/reasons").subscribe(
  //     (res) => {
  //       console.log(res, "response");
  //       if (res) {
  //         this.configuredReasons = res;
  //       }
  //     },
  //     (error) => {
  //       console.log(error, "err");
  //       this.errmsg = "Server Unreachable";
  //       this.configuredReasons = [];
  //       this.toastr.error("Server Unreachable");
  //     }
  //   );
  // }

  // initializeForm() {
  //   let autoreasonCode;
  //   console.log(this.configuredReasons.length, "this.configuredReasons");
  //   if (this.configuredReasons.length == 0) {
  //     autoreasonCode = "R1";
  //   } else {
  //     autoreasonCode = "R" + (this.configuredReasons.length + 1);
  //   }
  //   this.reasonform = this.formBuilder.group({
  //     reasonCode: new FormControl({ value: autoreasonCode, disabled: true }),
  //     reasonType: ["", [Validators.required]],
  //     reason: ["", [Validators.required]],
  //   });
  // }

  // get form() {
  //   return this.reasonform.controls;
  // }

  // openModal(content: any, data: any, act: any) {
  //   this.action = act;
  //   this.modalService.open(content, { backdrop: "static", centered: true });
  //   if (act === "Add") {
  //     this.initializeForm();
  //   } else if (act === "Edit") {
  //     if (data) {
  //       this.editReasonId = data.id
  //       console.log(data,"data")
  //       this.initializeForm();
  //       this.pathValue(this.reasonform, data);
  //     }
  //   }
  // }

  // pathValue(formGroup: FormGroup, data: any): void {
  //   const controls = formGroup.controls;
  //   for (const key in controls) {
  //     if (key == "reasonCode") {
  //       controls[key].disable();
  //     }
  //     controls[key].patchValue(data[key]);
  //   }
  // }

  // cancel() {
  //   this.modalService.dismissAll();
  //   this.submitted = false;
  //   this.action = "";
  // }

  // saveData() {
  //   this.submitted = true;
  //   if (this.reasonform.invalid) {
  //     return this.reasonform.controls;
  //   } else {
  //     this.reasonform.enable();
  //     if (this.action == "Add") {
  //       // Add
  //       this.dataservice
  //         .post("api/reasons", this.reasonform.value)
  //         .subscribe((res) => {
  //           console.log(res, "response");
  //           if (res) {
  //             this.cancel();
  //             this.toastr.success("Reason Added Successfully");
  //             this.getreasons();
  //           }
  //         });
  //     } else {
  //       // Update
  //       this.dataservice
  //         .put("api/reasons/" + this.editReasonId, this.reasonform.value)
  //         .subscribe((res) => {
  //           console.log(res, "response");
  //           if (res) {
  //             this.cancel();
  //             this.toastr.success("Reason Updated Successfully");
  //             this.getreasons();
  //           }
  //         });
  //     }
  //   }
  // }

  // openModalDelete(deletetemp, reason) {
  //   console.log(reason, "Reason");
  //   this.delreasonId = reason.id;
  //   this.modalService.open(deletetemp, { backdrop: "static", centered: true });
  // }

  // delete(id) {
  //   console.log(id, "idddd");
  //   this.dataservice.delete("api/reasons/" + id).subscribe((res) => {
  //     if (res) {
  //       this.toastr.success("Reason deleted Successfully");
  //       console.log(res, "res");
  //       this.modalService.dismissAll();
  //       this.getreasons();
  //     }
  //   });
  // }

  // onPageChange(pageNum: number): void {
  //   this.pageSize = this.itemsPerPage * (pageNum - 1);
  //   console.log(pageNum, this.pageSize, "pageNum");
  // }

  configuredReasons: any = [];
  reasonform: FormGroup;
  errmsg: string = "";
  delreasonId = "";
  currentPage = 1;
  itemsPerPage = 5;
  pageSize;
  action: any;
  editReasonId;
  submitted: boolean = false;
  searchText: any;
  issubReason = false;
  subReason: any = FormArray;
  configuredReasons1: any = [];
  hideSubReason = false;
  autoreasonCode: any;
  editUser: any;
  isCatagery: boolean = false;
  currentview = "Rejection";
  reasonTypeData: any = [
    { value: "rejection", reasonType: "Rejection" },
    { value: "downtime", reasonType: "Downtime" },
  ];
  // categoryTypeData: any = [
  //   { value: "downtime", reasonType: "Downtime" },
  //   { value: "plannedDowntime", reasonType: "Planned Downtime" },
  // ];
  selectedType: String = "rejection";
  // selectedCatagory: string = "plannedDowntime";
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Configuration" },
      { label: "Reasons", active: true },
    ];
    this.fetchData();
  }

  fetchData() {
    this.toastr.info("We are fetching data", "Info");
    this.getreasons();
  }

  getreasons() {
    const subscription = this.dataservice.get("api/reasons").subscribe(
      (res) => {
        console.log(res, "masterList");
        if (res) {
          this.toastr.clear();
          this.configuredReasons = res;
          this.selectType(this.selectedType);
        }
      },
      (error) => {
        this.errmsg = "Server Unreachable";
        this.configuredReasons = [];
        this.toastr.error("Server Unreachable");
      }
    );
    this.subscriptions.push(subscription);
  }

  selectType(data) {
    if (data == "rejection") {
      this.currentview = "Rejection";
      this.isCatagery = false;
    } else if (data == "downtime") {
      this.currentview = "Downtime";
      this.isCatagery = true;
    }
    let reaArr = [];
    this.configuredReasons.map((elm) => {
      if (data == elm["reasonType"] ) {
        reaArr.push(elm);
      } 
    });
    this.configuredReasons1 = reaArr;
  }

  configTypeChange(data) {
    this.issubReason = false;
    if (this.action != "Edit") {
      if (data.target.value == "downtime") {
        this.hideSubReason = true;
        this.subReason = [];
        this.reasonform.value["subReason"] = [];
        this.reasonform.get("subReason")["controls"] = [];
      } else {
        this.hideSubReason = false;
        this.subReason = [];
        this.reasonform.value["subReason"] = [];
        this.reasonform.get("subReason")["controls"] = [];
      }
    } else {
      if (data.target.value == "downtime") {
        this.hideSubReason = true;
        this.subReason = this.editUser["subReason"];
        this.issubReason = this.editUser["subReason"].length > 0 ? true : false;
        this.initializeForm();
        this.pathValue(this.reasonform, this.editUser);
        const subReasonArray = this.reasonform.get("subReason") as FormArray;
        this.subReason.forEach((elm) => {
          subReasonArray.push(
            this.formBuilder.group({
              subReason: [elm.subReason, Validators.required],
              subReasonCode: [elm.subReasonCode, Validators.required],
            })
          );
        });
      } else {
        this.hideSubReason = false;
        this.subReason = [];
        this.reasonform.value["subReason"] = [];
        this.reasonform.get("subReason")["controls"] = [];
      }
    }
  }

  initializeForm() {
    this.autoreasonCode;
    if (this.configuredReasons.length == 0) {
      this.autoreasonCode = "R1";
    } else {
      let num =
        this.configuredReasons[this.configuredReasons?.length - 1][
          "reasonCode"
        ].slice(1);
      this.autoreasonCode = "R" + (parseInt(num) + 1);
    }
    this.reasonform = this.formBuilder.group({
      reasonCode: new FormControl({
        value: this.autoreasonCode,
        disabled: true,
      }),
      reasonType: ["", [Validators.required]],
      reason: ["", [Validators.required]],
      issubReason: [false, [Validators.required]],
      subReason: this.formBuilder.array([]),
    });
  }

  createArr() {
    return this.formBuilder.group({
      subReasonCode: ["", [Validators.required]],
      subReason: ["", [Validators.required]],
    });
  }

  get form() {
    return this.reasonform.controls;
  }

  openModal(content: any, data: any, act: any) {
    this.action = act;
    this.modalService.open(content, { backdrop: "static", centered: true });
    if (act === "Add") {
      this.initializeForm();
    } else if (act === "Edit") {
      if (data) {
        console.log(data,"data")
        if(data['reasonType'] == "rejection"){
          this.editReasonId = data.id;
          this.editUser = data;
          this.subReason = data["subReason"];
          this.issubReason = data["subReason"].length > 0 ? true : false;
          this.hideSubReason = this.issubReason;
          this.initializeForm();
          this.pathValue(this.reasonform, data);
          this.reasonform.get("reasonType").disable();
          const subReasonArray = this.reasonform.get("subReason") as FormArray;
          this.subReason.forEach((elm) => {
            subReasonArray.push(
              this.formBuilder.group({
                subReason: [elm.subReason, Validators.required],
                subReasonCode: [elm.subReasonCode, Validators.required],
              })
            );
          });
        }else if(data['reasonType'] == "downtime"){
          this.hideSubReason=true
          this.editReasonId = data.id;
          this.editUser = data;
          this.subReason = data["subReason"];
          this.issubReason = data["subReason"].length > 0 ? true : false;
          this.initializeForm();
          this.reasonform.get('issubReason')?.disable();
          this.pathValue(this.reasonform, data);
          this.reasonform.get("reasonType").disable();
          const subReasonArray = this.reasonform.get("subReason") as FormArray;
          this.subReason.forEach((elm) => {
            subReasonArray.push(
              this.formBuilder.group({
                subReason: [elm.subReason, Validators.required],
                subReasonCode: [elm.subReasonCode, Validators.required],
              })
            );
          });
        }
      }
    }
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      if (key == "reasonCode") {
        controls[key].disable();
      }
      controls[key].patchValue(data[key]);
    }
  }

  subReasonCheck(e) {
    this.issubReason = e.target.checked;
    if (this.issubReason) {
      this.AddSubReason();
    } else {
      this.subReason = [];
      this.reasonform.value["subReason"] = [];
      this.reasonform.get("subReason")["controls"] = [];
    }
  }

  // subReasonAdding(data, key) {
  //   if (key == "subReasonCode") {
  //   } else if (key == "subReason") {
  //   }
  // }

  AddSubReason() {
    this.subReason = this.reasonform.get("subReason") as FormArray;
    this.subReason.push(this.createArr());
  }

  delSubReason(index) {
    (<FormArray>this.reasonform.get("subReason")).removeAt(index);
    const subReason = this.reasonform.get("subReason") as FormArray;
    console.log(subReason['value'])
    if(subReason['value'].length == 0){
      this.issubReason=false;
      this.reasonform.get('issubReason')?.setValue(false);
      this.reasonform.get('issubReason')?.enable();
    }else{
      this.issubReason=true;
      this.reasonform.get('issubReason')?.disable();
    }
  }

  cancel() {
    this.modalService.dismissAll();
    this.submitted = false;
    this.issubReason = false;
    this.hideSubReason = false;
    this.action = "";
  }

  saveData() {
    this.submitted = true;
    this.form.enable;
    if (this.reasonform.invalid) {
      return this.reasonform.controls;
    } else {
      this.reasonform.enable();
      if (this.action == "Add") {
        // Add
        console.log(this.reasonform.value, "this.reasonform.value");
        const subscription = this.dataservice
          .post("api/reasons", this.reasonform.value)
          .subscribe(
            (res) => {
              if (res) {
                this.cancel();
                this.toastr.success("Reason Added Successfully", "Success");
                this.getreasons();
              }
            },
            (error) => {
              if (error == "Server Unreachable")
                this.toastr.error("Server Unreachable", "Error");
              else this.toastr.error(error, "Error");
            }
          );
        this.subscriptions.push(subscription);
      } else {
        // Update
        console.log(this.reasonform.value, "this.reasonform.value Edit");
        this.reasonform.get("reasonType").enable();
        const subscription = this.dataservice
          .put(
            "api/reasons/" + this.editReasonId,
            this.reasonform.value
          )
          .subscribe(
            (res) => {
              console.log(res);
              if (res) {
                this.cancel();
                this.toastr.success("Reason Updated Successfully", "Success");
                this.getreasons();
              }
            },
            (error) => {
              if (error == "Server Unreachable")
                this.toastr.error("Server Unreachable", "Error");
              else this.toastr.error(error);
            }
          );
        this.subscriptions.push(subscription);
      }
    }
  }

  openModalDelete(deletetemp, reason) {
    this.delreasonId = reason['id'];
    this.subReason = reason["subReason"];
    this.modalService.open(deletetemp, { backdrop: "static", centered: true });
  }

  delete(id) {
    const subscription = this.dataservice.delete("api/reasons/" + id).subscribe(
      (res) => {
        if (res) {
          this.toastr.success("Reason deleted Successfully", "Success");
          console.log(res);
          this.modalService.dismissAll();
          this.getreasons();
        }
      },
      (error) => {
        if (error == "Server Unreachable")
          this.toastr.error("Server Unreachable", "Error");
        else this.toastr.error(error, "Error");
      }
    );
    this.subscriptions.push(subscription);
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
