import { filter } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";
import { DataService } from "src/app/shared/Services/data.service";
import { HttpClient } from "@angular/common/http";
import * as echarts from "echarts";
import { ShareService } from "src/app/shared/Services/shared.service";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

@Component({
  selector: "app-operator-dashboard",
  templateUrl: "./operator-dashboard.component.html",
  styleUrls: ["./operator-dashboard.component.scss"],
})
export class OperatorDashboardComponent implements OnInit {
  // donughnutChart: ChartType;
  ganttBool: boolean = true;
  private destroy$: Subject<void> = new Subject<void>();
  shift_Time: any;
  isCollapsed = true;
  isDisabled: boolean = true;
  role: any;
  device_bar_chart: any;
  custId: any;
  machine_List: any = [];
  showGantt: boolean = false;
  selectedCars = "All Shift";
  chatSubmit: boolean;
  formData: FormGroup;
  deviceName;
  apiInterval: any;
  currentShift;
  params: any;
  intervalFlag: boolean = true;
  deviceType;
  avail: any;
  prodLoss: any;
  energycost: any;
  showHourchart: boolean = false;
  hourlyData: any;
  userData: any;
  ngAfterViewInit(): void {}
  current;
  LUT;
  status;
  m_effic;
  parts;
  combinedData;
  availability;
  quality;
  perf;
  pieStatus;
  r_power;
  a_power;
  energy;
  t_energy;
  // showPieChart: boolean = false;
  finalcombinedData: any = [];
  selectedDateOption: string = "Live";
  historycombinedData: any = [];
  selectedMachine: any;
  count: any;
  previousDays: any = [];
  type: any;
  showChart: boolean = false;
  pie_response: any;
  clustr_Chart: boolean = false;
  cluster_data;
  showCombined: boolean = false;
  historydata: boolean = false;
  historyDatas = [];
  selecteMachineId: String = "";
  selectReason: any;
  typeData: any = [];
  selectedRejection: String = "";
  // plannedPart: any = [];
  action: any;
  sampleName: String = "";

  startDate: any;
  maxDate: any;
  minDate: any;
  shiftsArr: any = [];
  machineData: any = [];
  partData: any = [];
  rejectionData: any = [];
  tempData: any = [];
  selectedPart: any;
  selectedPartproduced: 0;
  selectedPartrejected: 0;
  reasonData: any = [];
  selectedShifts: any;
  pageSize;
  currentPage = 1;
  itemsPerPage = 3;
  pageSize1;
  currentPage1 = 1;
  itemsPerPage1 = 5;
  pageSize2;
  currentPage2 = 1;
  itemsPerPage2 = 3;
  searchTerm2: any = "";
  searchTerm: any = "";
  validcount: any;
  showModel: boolean = false;
  filteredData: any = [];
  filteredsampleData: any = [];
  searchRecord: any = "";
  isbtn: boolean = false;
  currentTime: Date;
  downtimeType: string[] = ["Idle", "Standby"];
  selectedType: string = "Idle";
  showfields: boolean = false;
  private deactivate: boolean = true;
  addRecord: boolean = true;
  timerRunning = false;
  startTime: any = "";
  timer: any;
  endTime: any = "";
  duration;
  validationform: FormGroup;
  submitted: boolean = false;
  deviceList: any;
  errmsg: String = "";
  sampleData: any = [];
  hideMachine: boolean = false;
  shiftId: any;
  shiftName: any;
  singlebool: boolean = true;
  searchCount: any;
  validNumber;
  addBool = true;
  timeBool = false;
  rejectionReasons: any = [];
  downtimeReasons: any = [];
  downtimevalues: any = [];
  endtimeData: any = [];
  subReasonData: any = [];
  downtimeData: any;
  selectedTimezone: any;
  checkBool: boolean = true;
  ganttData: any;
  downData:any = [];
  finalData:any = [];
  orderedData: any = [];
  showData:boolean = false

  private subscriptions: Subscription[] = [];
  constructor(
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private http: HttpClient,
    private router: Router,
    private sharedService: ShareService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  statData = [
    {
      icon: "ri-stack-line",
      title: "Number of Sales",
      value: "1452",
    },
    {
      icon: "ri-store-2-line",
      title: "Sales Revenue",
      value: "$ 38452",
    },
    {
      icon: "ri-briefcase-4-line",
      title: "Average Price",
      value: "$ 15.4",
    },
    {
      icon: "ri-briefcase-4-line",
      title: "Average Price",
      value: "$ 15.4",
    },
  ];

  options = {
    layers: [
      tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909),
  };

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = this.userData["user"]["custId"];
    this.role = this.userData["user"]["role"];
    this.shift_Time = this.userData["user"]["currentShift"];
    this.shiftId = this.shift_Time["shiftId"];
    this.shiftName = this.shift_Time["shiftName"];
    this.selectedShifts = this.shift_Time["usershiftId"];
    this.selectedTimezone = this.shift_Time["timeZone"];
    this.getshiftDetails();
    this.maxDate = moment(this.shift_Time["currentDate"]).format("YYYY-MM-DD");
    this.minDate = moment(this.shift_Time["currentDate"])
      .subtract(2, "day")
      .format("YYYY-MM-DD");
    this.startDate = this.maxDate;
    this.getReasonData();
    this.initalizeform();
    this.updateTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  }
  result:any;
  selectshift:any;
  getshiftDetails() {
    const subscription = this.dataservice
      .get(`api/shift?custId=${this.custId}`)
      .subscribe((res) => {
        if (res) {
          this.shiftsArr = res;
          this.selectshift = this.shiftsArr.filter((val) => {
            if (val.usershiftId == this.selectedShifts) {
              return val;
            }
          });
          
          this.getData();

        }
      });
    this.subscriptions.push(subscription);
  }

  getData() {
    const subscription = this.dataservice
      .get(
        `api/device?custId=${this.userData["user"]["custId"]}&operatorId=${this.userData["user"]["name"]}&shiftName=${this.shiftName}&date=${this.startDate}`
      )
      .subscribe((res) => {
        this.machine_List = res;
        if (this.machine_List.length != 0) {
          this.showData = true
          this.selectedMachine = this.machine_List[0]["deviceName"];
          this.selecteMachineId = this.machine_List[0]["deviceId"];
          this.getDowntimeData();
          this.getRecords();
          this.result = this.gettimestamp(this.selectshift);
          var shared_data = {
            custID: this.custId,
            deviceID: this.machine_List[0]["deviceId"],
            deviceName: this.machine_List[0]["deviceName"],
            deviceType: this.machine_List[0]["deviceType"],
            startTime:this.result['starttime'],
            endTime:this.result['endtime'],
          };
          if (Object.values(shared_data).length != 0) {
            this.params = shared_data;
            this.getMainData();
          }
        }else{
          this.showData = false;
        }
      });
    this.subscriptions.push(subscription);
  }
  getRecords() {
    const subscription = this.dataservice
      .get(`api/getsample?custId=${this.userData["user"]["custId"]}&date=${moment(`${this.startDate} ${this.selectshift[0]['startTime']}`).format("DD-MM-YYYY HH:mm:ss")}&shiftId=${this.userData["user"]["currentShift"]["shiftId"]}&deviceId=${this.selecteMachineId}`)
      .subscribe(
        (res) => {
          if (res) {
            this.sampleData = res;
            this.filterSampleData();
          }
        },
        (err) => {
          this.errmsg = err;
          this.toastr.warning("Server Unreachable. Please try again");
        }
      );
    this.subscriptions.push(subscription);
  }
  getMainData() {
    this.getPartData();
    this.currentShift = this.shift_Time;
    this.selectedCars = this.currentShift["shiftName"];
    this.params.shiftName = "all";
    this.deviceName = this.params.deviceName;
    this.deviceType = this.params.deviceType;
    delete this.params.deviceName;
    this.getCustData(this.params);
    this.gattchart(this.params);
    this.getPartCount(this.params);
    console.log(this.isToday,this.selectedShifts,this.shiftName,"beforeIf")
    if(this.isToday && this.selectedShifts == this.shift_Time["usershiftId"]){
      console.log(this.isToday,this.selectedShifts,this.shiftName,"afterIf")
      this.apiInterval = setInterval(() => {
        if (this.intervalFlag === true) {
          this.getCustData(this.params);
          this.gattchart(this.params);
          this.getPartCount(this.params);
        }
      }, 30000);
    }else{
      if (this.apiInterval) {
        clearInterval(this.apiInterval);
      }
    }
    this.dayarray();
  }

  selectedShift($event) {
    this.selectedCars = $event;
    if (
      this.currentShift.usershiftId === this.selectedCars ||
      this.selectedCars === "All Shift"
    ) {
      this.intervalFlag = true;
    } else {
      this.intervalFlag = false;
    }
    if (this.selectedCars === "All Shift") {
      this.params.shiftName = "all";
    } else {
      this.params.shiftName = this.selectedCars;
    }
    this.showChart = false;
    this.getCustData(this.params);
    this.gattchart(this.params);
    this.getPartCount(this.params);
  }

  compareTimes(time1, time2) {
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);

    if (hours1 !== hours2) {
      return hours1 - hours2;
    } else if (minutes1 !== minutes2) {
      return minutes1 - minutes2;
    } else {
      return seconds1 - seconds2;
    }
  }

  getSecondsFromString(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  epochToHHMMSS(epochTime) {
    if (typeof epochTime === "undefined") {
      return "-";
    }

    const isMilliseconds = epochTime > 9999999999;
    const date = new Date(isMilliseconds ? epochTime : epochTime * 1000);

    // Extract day, month, year, hours, minutes, and seconds in local time
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, "0");

    // Format the date and time as dd/mm/yy hh:mm:ss AM/PM in local time
    return `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
    // return formattedTime;
  }

  getCustData(param) {
    console.log(param)
    if (param.shiftName) {
    } else {
      this.params.shiftName = this.currentShift.usershiftId;
    }
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/device_oee?code=vbtZrAQPwenO4iwgJR58jO8pLSM0VUo8MtYetHaCM1KwAzFu3ekVbg==",
        param
      )
      .subscribe((res: any) => {
        this.current = res["current"];
        this.r_power = res["real_power"];
        this.a_power = res["apparent_power"];
        this.energy = res["energy_consumption"];
        this.t_energy = res["total_energy"];
        this.LUT = res["LUT"];
        this.m_effic = res["oee"] ? res["oee"].toFixed(1) : 0;
        this.perf = res["performance"] ? res["performance"].toFixed(1) : 0;
        this.quality = res["quality"] ? res["quality"].toFixed(1) : 0;
        this.availability = res["availability"]
          ? res["availability"].toFixed(1)
          : 0;
        this.status = res["status"];
        this.count = res?.part_count || "-";
      });
  }
  onMachineChange($event) {
    const selectedData = this.machine_List.find(
      (device) => device.deviceName == $event.target.value
    );
    this.selectedMachine = selectedData.deviceName;
    this.selecteMachineId = selectedData.deviceId;
    this.getRecords();
    // this.getPartData();
    // this.getRejectionData();
    this.getDowntimeData();
    this.result = this.gettimestamp(this.selectshift);
          let shared_data = {
            custID: this.custId,
            deviceID: selectedData["deviceId"],
            deviceName: selectedData["deviceName"],
            deviceType: selectedData["deviceType"],
            startTime:this.result['starttime'],
            endTime:this.result['endtime'],
          };
    if (Object.values(shared_data).length != 0) {
      this.params = shared_data;
      this.getMainData();
    }
  }

  getPartCount(param) {
    if (param.shiftName) {
    } else {
      this.params.shiftName = this.currentShift.usershiftId;
    }
    this.dataservice.partCount(param).subscribe(
      (res) => {
        this.parts =
          res["partcount_Type"] == "AI" ? res["pattern_count"] : this.count;
        this.type = res["partcount_Type"];
      },
      (error) => {}
    );
  }

  gattchart(param) {
    if (param.shiftName) {
    } else {
      this.params.shiftName = this.currentShift.usershiftId;
    }
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/device_gantt_chart?code=rTjgE3saT7l_LNXSmiBDlvt3C1_OFBm3as-HY9SeV1YDAzFu-8-HSg==",
        param
      )
      .subscribe((res) => {
        if (res["time"][0]?.length > 0) {
          this.finalcombinedData = res;
          this.devicebarchart(param);
        }
      });
  }
  devicebarchart(param) {
    if (param.shiftName) {
    } else {
      this.params.shiftName = this.currentShift.usershiftId;
    }

    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/device_bar_chart_testing?code=AoThSCnmA2IYtXSZY4y1U8zmAMw5qGmdfCX-KMgok7fNAzFuSeIoGw==",
        // "https://k2-oee-prod.azurewebsites.net/api/device_bar_chart?code=mafaTgE3c-yo-6dAVaaD0BYbWI4WLxbG7p846_5Q_2vNAzFuhhVRZg%3D%3D",
        param
      )
      .subscribe(
        (res) => {
          this.historyDatas = [];
          this.device_bar_chart = res;
          if (res.hasOwnProperty("chart_data")) {
            var pieStatus = res["chart_data"][0];
            const outputData = pieStatus.map((item) => {
              var color;
              if (item.status === "dryrun") {
                color = "#ffff94";
              } else if (item.status === "running") {
                color = "#548237";
              } else if (item.status === "idle") {
                color = "#f7c030";
              } else if (item.status === "warning") {
                color = "#bfe0a5";
              } else if (item.status === "alarm") {
                color = "#ee4932";
              }
              return {
                value:
                  Math.sign(item.duration) == -1
                    ? Math.abs(item.duration)
                    : item.duration,
                name: item.status,
                percent: item.percent,
                itemStyle: {
                  color: color,
                },
              };
            });
            this.pie_response = outputData;
          }
          if (
            res.hasOwnProperty("overall_status") &&
            res["overall_status"].length
          ) {
            this.showChart = true;
            this.historyDatas = res["overall_status"];
            // this.typeData = this.historyDatas.filter(
            //   (val) => val["name"] == this.selectedType
            // );
            // if(this.typeData.length){

            //  this.orderedData = this.sortedTypeData(this.typeData)
            // }
          }
        },
        (error) => {
          this.showChart = false;
        }
      );
  }
  sortedTypeData(val) {
    return val
      .filter((item) => /* Add filtering logic if needed */ true)
      .sort((a, b) => b.fromTo.localeCompare(a.fromTo));
  }
  secondsToHms(d) {
    d = Number(d);
    if (Math.sign(d) == -1) {
      d = Math.abs(d);
    }
    const hours = Math.floor(d / 60);
    const minutes = Math.floor(d % 60);
    const seconds = Math.round((d * 60) % 60);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }

  houers(data) {
    let valueInHMS = "00:00:00";
    if (this.pie_response) {
      let existing = this.pie_response.find((item) => item.name == data);
      if (existing == undefined) {
        valueInHMS = "00:00:00";
      } else {
        valueInHMS = this.secondsToHms(existing.value);
      }
    }
    return valueInHMS;
  }
  statusColor(data) {
    switch (data) {
      case 1: {
        return "#ffff94";
        break;
      }
      case 0: {
        return "#f7c030";
        break;
      }
      case 2: {
        return "#548237";
        break;
      }
      case 3: {
        return "#bfe0a5";
        break;
      }
      case 4: {
        return "#ee4932";
        break;
      }
      default: {
        return "#0092ff";
        //statements;
        break;
      }
    }
  }

  pieChart(data) {
    return {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          var value = params.value;
          var hours = Math.floor(value / 60);
          var minutes = Math.floor(value % 60);
          var seconds = Math.floor((value * 60) % 60);
          var formattedValue =
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0") +
            ":" +
            seconds.toString().padStart(2, "0");

          return `${params.seriesName}<br/>${params.name}: ${formattedValue} (${params.percent}%)`;
        },
      },
      legend: {
        top: "5%",
        left: "center",
        // orient: "vertical",
        // x: "left",
        // data: ["Running", "Idle"],
        // textStyle: { color: "#8791af" },
      },
      // color: ["#f7ff00", "#5fff00", "#f1b44c", "#50a5f1", "#34c38f"],
      series: [
        {
          name: "Status",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            formatter: function (params) {
              // If axis.type is 'time'
              return "some text" + echarts.format.formatTime(params.value);
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: data,
        },
      ],
    };
  }
  dayarray() {
    const today = new Date(); // Get the current date

    // Subtract one day to get yesterday's date
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Subtract another day to get the day before yesterday
    const dayBeforeYesterday = new Date(today);
    dayBeforeYesterday.setDate(today.getDate() - 2);

    // Create an array with both yesterday's and the day before yesterday's date in ISO format
    this.previousDays = [
      yesterday.toISOString().split("T")[0], // Format date to YYYY-MM-DD
      dayBeforeYesterday.toISOString().split("T")[0], // Format date to YYYY-MM-DD
    ];
  }
  historyChart() {
    if (this.selectedDateOption == "Live") {
      this.historycombinedData = [];
      this.historydata = false;
      this.intervalFlag = true;
      this.gattchart(this.params);
      this.toastr.success("Live On, Please wait we are fetching record");
    } else {
      this.historycombinedData = [];
      var param = {
        custID: this.params["custID"],
        deviceID: this.params["deviceID"],
        date: this.selectedDateOption,
      };
      this.history_chart(param);

      this.toastr.warning("Live Off, Please wait we are fetching record");
      this.intervalFlag = false;
    }
  }

  combined() {
    this.showCombined = !this.showCombined;
  }

  history_chart(param) {
    this.showChart = false;
    this.historydata = false;
    this.historyDatas = [];
    this.dataservice.history_chart(param).subscribe(
      (res) => {
        if (res["overall_status"]?.length > 0) {
          this.showChart = true;
          this.historydata = true;
          this.historyDatas = res["overall_status"];
        }
      },
      (error) => {}
    );
  }

  showDeviceInfo() {}
  openModal(infotemplate: any) {
    this.modalService.open(infotemplate, {
      backdrop: "static",
      centered: true,
      size: "xl",
    });
  }
  isSame: boolean = false;

  opendowntimeReason(contentTemp, data) {
    this.downtimeData = data;
    this.endtimeData[0] = this.generateTimeArray(
      this.downtimeData.fromTo.split("-")[0].split(".")[0].slice(0, 5),
      this.downtimeData.fromTo.split("-")[1].split(".")[0].slice(0, 6)
    );
    const starttime = this.downtimeData.fromTo.split("-")[0].split(".")[0];
    const formattedStartTime = moment(starttime, "HH:mm:ss").format("HH:mm");
    let datas = {
      startTime: formattedStartTime,
      endTime: "",
      reasonCode: this.downtimeData["reasonData"]["reasonCode"],
      subReasoncode: this.downtimeData["reasonData"]["subReasoncode"],
      reason: this.downtimeData["reasonData"]["reason"],
      subReason: this.downtimeData["reasonData"]["subReason"],
    };
    this.downtimevalues.push(datas);
    this.changeReason(datas["reason"], 0);

    if (this.endtimeData[0].length == 1 || this.endtimeData[0].length == 2) {
      this.isSame = true;
      this.modalService.open(contentTemp, {
        backdrop: "static",
        centered: true,
      });
    } else {
      this.isSame = false;
      this.modalService.open(contentTemp, {
        backdrop: "static",
        centered: true,
        size: "xl",
      });
    }
  }

  getReasonData() {
    const subscription = this.dataservice.get("api/reasons").subscribe(
      (res) => {
        if (res) {
          this.reasonData = res;

          this.rejectionReasons = this.filterByReasonType(
            this.reasonData,
            "rejection"
          );
          this.downtimeReasons = this.filterByReasonType(
            this.reasonData,
            "downtime"
          );
        }
      },
      (error) => {
        this.errmsg = "Server Unreachable";
        this.reasonData = [];
        this.toastr.error("Server Unreachable");
      }
    );
    this.subscriptions.push(subscription);
  }
  filterByReasonType(data, type) {
    return data.filter((item) => item.reasonType === type);
  }

  AddSubReason() {
    let data = {};
    if (this.downtimevalues.length == 0) {
      this.endtimeData[0] = this.generateTimeArray(
        this.downtimeData.fromTo.split("-")[0].split(".")[0].slice(0, 5),
        this.downtimeData.fromTo.split("-")[1].split(".")[0].slice(0, 6)
      );
      let time = this.downtimeData.fromTo.split("-")[0].split(".")[0];
      let timeInMinutes = time.split(":").slice(0, 2).join(":");
      data = {
        startTime: timeInMinutes,
        endTime: "",
        reason: "",
        subReason: "",
      };
    } else {
      const lastRow = this.downtimevalues[this.downtimevalues.length - 1];
      if (!lastRow.endTime || !lastRow.reason) {
        this.toastr.warning(
          "Please select End Time and Reason before adding a new entry."
        );
        return;
      }
      let previousEndTime = lastRow.endTime; //moment(lastRow.endTime,'hh:mm').add(1,'millisecond').format('hh:mm:ss:sss');

      data = {
        startTime: previousEndTime,
        endTime: "",
        reason: "",
        subReason: "",
        reasonCode: "",
        subReasoncode: "",
      };
    }
    if (Object.values(data).length != 0) {
      this.downtimevalues.push(data);
    }
  }
  changeFormat(data) {
    return moment(data).format("HH:mm");
  }

  changeTime(e, index) {
    const lastRow = this.downtimevalues[this.downtimevalues.length - 1];
    if (lastRow.startTime != e.target.value) {
      this.endtimeData[index + 1] = this.endtimeData[0].filter(
        (time: string) => time >= lastRow.endTime
      );
      this.downtimevalues[index].endTime = e.target.value;
      let lastData;
      if (this.endtimeData[index + 1].length == 1) {
        lastData = this.endtimeData[index + 1];
      }
      if (lastData == e.target.value) {
        this.addBool = false;
      } else {
        this.addBool = true;
      }
      this.timeBool = false;
    } else if (lastRow.endTime == e.target.value) {
      this.timeBool = true;
      this.toastr.warning("Selected time is same.Please change time");
    }
  }

  changeReason(e, index) {
    this.downtimevalues[index].reason = e;
    let data = this.downtimeReasons.find((val) => val.reason === e);

    this.subReasonData[index] = data["subReason"];
    if(this.subReasonData[index].length){
      this.downtimevalues[index]['subReason']=this.subReasonData[index][0]['subReason']
    }
    this.downtimevalues[index].reasonCode = data["reasonCode"];
  }
  changesubReason(e, index) {
    this.downtimevalues[index].subReason = e.target.value;
    let data = this.subReasonData[index].find(
      (val) => val.subReason === e.target.value
    );
    this.downtimevalues[index].subReasoncode = data["subReasonCode"];
  }
  removeRow(index: number): void {
    if (index > -1 && index < this.downtimevalues.length) {
      this.downtimevalues.splice(index, 1);
      this.addBool = true;
      this.timeBool = false;
    }
  }

  // generateTimeArray(startTime, endTime) {
  //   const [startHour, startMinute] = startTime.split(":").map(Number);
  //   const [endHour, endMinute] = endTime.split(":").map(Number);

  //   const result = [];
  //   let currentHour = startHour;
  //   let currentMinute = startMinute;

  //   while (
  //     currentHour < endHour ||
  //     (currentHour === endHour && currentMinute <= endMinute)
  //   ) {
  //     // Add current time in "HH:MM" format
  //     result.push(
  //       `${String(currentHour).padStart(2, "0")}:${String(
  //         currentMinute
  //       ).padStart(2, "0")}`
  //     );

  //     // Increment the time by 1 minute
  //     currentMinute++;
  //     if (currentMinute === 60) {
  //       currentMinute = 0;
  //       currentHour++;
  //     }
  //   }

  //   return result;
  // }
  generateTimeArray(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
  
    // Handle the special case where startTime and endTime are identical
    if (startHour === endHour && startMinute === endMinute) {
      return [`${String(startHour).padStart(2, "0")}:${String(startMinute).padStart(2, "0")}`];
    }
  
    const result = [];
    let currentHour = startHour;
    let currentMinute = startMinute;
  
    while (true) {
      // Add current time in "HH:MM" format
      result.push(
        `${String(currentHour).padStart(2, "0")}:${String(
          currentMinute
        ).padStart(2, "0")}`
      );
  
      // Increment the time by 1 minute
      currentMinute++;
      if (currentMinute === 60) {
        currentMinute = 0;
        currentHour++;
      }
  
      // Wrap around to the next day if necessary
      if (currentHour === 24) {
        currentHour = 0;
      }
  
      // Break the loop when we reach the end time
      if (currentHour === endHour && currentMinute === endMinute) {
        result.push(
          `${String(currentHour).padStart(2, "0")}:${String(
            currentMinute
          ).padStart(2, "0")}`
        );
        break;
      }
    }
    return result;
  }

  selectChoice($event) {
    if ($event.target.value == "single") {
      this.singlebool = true;
      this.searchCount = 0;
      this.getCount();
    } else if ($event.target.value == "multiple") {
      this.singlebool = false;
    }
  }

  getCount() {
    if (
      this.searchCount &&
      this.searchCount <= this.validcount &&
      this.searchCount >= 0
    ) {
      let selected = 0;
      this.rejectionData = this.rejectionData.map((val) => {
        if (!val.isRejected) {
          if (this.searchCount > selected) {
            val["flag"] = true;
            selected++;
          } else {
            val["flag"] = false;
          }
        } else {
          val["flag"] = false;
        }
        return val;
      });
      this.showModel = true;
      this.validNumber = this.searchCount;
    } else {
      this.showModel = false;
      this.rejectionData = this.rejectionData.map((val) => {
        val["flag"] = false;
        return val;
      });
    }
  }
  checkflag(item) {
    return item;
  }
  multiReasonclose() {
    this.modalService.dismissAll();
    this.searchCount = 0;
    this.getCount();
  }
  multidownReason() {
    if (!this.isSame) {
      const lastRow = this.downtimevalues[this.downtimevalues.length - 1];
      if (!lastRow.endTime || !lastRow.reason) {
        this.toastr.warning("Please select Reason before Save.");
        return;
      }
      const baseStartTime = moment(
        this.downtimeData.fromTo.split("-")[0],
        "HH:mm:ss.SSS"
      );
      const baseEndTime = moment(
        this.downtimeData.fromTo.split("-")[1],
        "HH:mm:ss.SSS"
      );
      const finalRes = this.downtimevalues.map((reason, index) => {
        const reasonStart = moment(reason.startTime, "HH:mm");
        const reasonEnd = moment(reason.endTime, "HH:mm");

        // const fromToStart = index === 0 ? baseStartTime : reasonStart.add(1, "second");
        const fromToStart = index === 0 ? baseStartTime : reasonStart;
        const fromToEnd =
          index === this.downtimevalues.length - 1 ? baseEndTime : reasonEnd;

        const duration = moment.duration(fromToEnd.diff(fromToStart));
        const durationFormatted = `${String(duration.hours()).padStart(
          2,
          "0"
        )}:${String(duration.minutes()).padStart(2, "0")}:${String(
          duration.seconds()
        ).padStart(2, "0")}.${String(duration.milliseconds()).padStart(
          3,
          "0"
        )}`;
        return {
          fromTo: `${fromToStart.format("HH:mm:ss.SSS")} - ${fromToEnd.format(
            "HH:mm:ss.SSS"
          )}`,
          RowKey: this.downtimeData.RowKey,
          id: this.downtimeData.id,
          name: this.downtimeData.name,
          value: [
            0,
            moment
              .tz(
                `${this.startDate} ${fromToStart.format("HH:mm:ss.SSS")}`,
                "YYYY-MM-DD HH:mm:ss.SSS",
                this.selectedTimezone
              )
              .valueOf(),
            moment
              .tz(
                `${this.startDate} ${fromToEnd.format("HH:mm:ss.SSS")}`,
                "YYYY-MM-DD HH:mm:ss.SSS",
                this.selectedTimezone
              )
              .valueOf(),
            durationFormatted,
          ],
          itemStyle: this.downtimeData.itemStyle,
          reasonData: {
            reasonCode: reason.reasonCode,
            subReasoncode: reason.subReasoncode,
            reason: reason.reason,
            subReason: reason.subReason,
          },
        };
      });
      this.addDowntimeReason(
        finalRes,
        this.downtimeData.RowKey,
        this.downtimeData.id,
        "multi"
      );
    } else {
      const lastRow = this.downtimevalues[this.downtimevalues.length - 1];
      if (!lastRow.reason) {
        this.toastr.warning("Please select Reason before Save.");
        return;
      }
      let reasonObj = {};
      reasonObj["reasonCode"] = lastRow["reasonCode"];
      reasonObj["subReasoncode"] = lastRow["subReasoncode"];
      reasonObj["reason"] = lastRow["reason"];
      reasonObj["subReason"] = lastRow["subReason"];

      let data = {};
      (data["fromTo"] = this.downtimeData.fromTo),
        (data["itemStyle"] = this.downtimeData.itemStyle),
        (data["name"] = this.downtimeData.name),
        (data["value"] = this.downtimeData.value),
        (data["reasonData"] = reasonObj);
      this.addDowntimeReason(
        data,
        this.downtimeData.RowKey,
        this.downtimeData.id,
        "single"
      );
    }
  }
  
  addDowntimeReason(data, rowkey, id, type) {
    if (type == "multi") {
      this.finalData.push(...data);
    } else {
      this.finalData.push(data);
    }
    this.dataservice
      .post(`downtime/entry?RowKey=${rowkey}&id=${id}`, this.finalData)
      .subscribe((res) => {
        if (res) {
          this.toastr.success("Dowtime Reason Added Successfully");
          this.getDowntimeData();
          this.multidownclose();
          this.finalData = [];
        }
      });
  }
  multidownclose() {
    this.downtimevalues = [];
    this.endtimeData = [];
    this.addBool = true;
    this.timeBool = true;
    this.modalService.dismissAll();
  }

  toggleFlag(item: any): void {
    item.flag = !item.flag; // Toggle the flag value
    this.filteredData = [...this.filteredData]; // Update the reference to trigger change detection if needed
  }

  onReason(item) {
    let data = {};
    data["Reason"] = item.Reason;
    data["usershiftId"] = item.usershiftId;
    data["custId"] = item.custId;
    data["date"] = item.date;
    data["partId"] = item.partId;
    data["partName"] = item.partName;
    data["time"] = item.time;
    data["deviceId"] = item.deviceId;
    if (item.Reason) {
      data["isRejected"] = true;
    } else {
      data["isRejected"] = false;
    }
    this.dataservice.put(`rejection/entry/?id=${item.id}`, data).subscribe(
      (res) => {
        if (res) {
          this.getRejectionData();
        }
      },
      (error) => {}
    );
  }

  onReason2($event) {
    this.selectedRejection = $event.target.value;
  }

  multiReason() {
    let data = this.rejectionData
      .filter((val) => val.flag === true)
      .map((val) => {
        let datas = {};
        datas["id"] = val.id;
        datas["Reason"] = this.selectedRejection;
        datas["usershiftId"] = val.usershiftId;
        datas["custId"] = val.custId;
        datas["date"] = val.date;
        datas["partId"] = val.partId;
        datas["partName"] = val.partName;
        datas["time"] = val.time;
        datas["deviceId"] = val.deviceId;
        datas["isRejected"] = !!this.selectedRejection;
        return datas;
      });
    this.dataservice.put(`rejection/entry/`, data).subscribe(
      (res) => {
        if (res) {
          this.getRejectionData();
          this.multiReasonclose();
        }
      },
      (error) => {}
    );
  }
  getPartData() {
    const subscription = this.dataservice
      .get(
        `mapping/operatorMapping?date=${this.startDate}&shiftName=${this.shiftName}&deviceName=${this.selectedMachine}&custId=${this.custId}&operatorId=${this.userData["user"]["name"]}`
      )
      .subscribe(
        (res: any) => {
          if (res.length) {
            this.partData = res[0]["target"];
            this.selectedPart = this.partData[0]["partName"];
            this.selectedPartproduced = this.partData[0]["partProduced"];
            this.getRejectionData();
          }else{
            this.partData=[]
          }
        },
        (error) => {
          this.partData = [];
        }
      );
    this.subscriptions.push(subscription);
  }

  getDowntimeData() {
    const subscription = this.dataservice
      .get(
        `downtime/entry?date=${this.startDate}&deviceId=${this.selecteMachineId}&custId=${this.custId}`
      )
      .subscribe(
        (res: any) => {
          if (res) {
            this.downData = res["Overall_status"];
            this.typeData = this.downData.filter(
              (val) => val["name"] == this.selectedType
            );
            console.log(this.typeData,"else came")
            if (this.typeData.length) {
              this.orderedData = this.sortedTypeData(this.typeData);
            }else{
              console.log("else came")
              this.orderedData=[];
            }
          }else{
            this.orderedData=[];
          }
        },
        (error) => {
          this.downData = [];
          this.orderedData=[];
        }
      );
    this.subscriptions.push(subscription);
  }

  getRejectionData() {
    this.result = this.gettimestamp(this.selectshift);
    const subscription = this.dataservice
      .get(
        `rejection/entry?date=${this.startDate}&shiftId=${this.shiftId}&startTime=${this.result["starttime"]}&endTime=${this.result["endtime"]}&usershiftId=${this.selectedShifts}&deviceId=${this.selecteMachineId}&custId=${this.custId}&partName=${this.selectedPart}`
      )
      .subscribe(
        (res: any) => {
          if (res) {
            this.rejectionData = res;
            this.filterData();
            this.selectedPartproduced = this.rejectionData
              ? this.rejectionData.length
              : 0;
            this.selectedPartrejected = this.rejectionData.filter(
              (item) => item.isRejected
            ).length;
            this.validcount =
              this.selectedPartproduced - this.selectedPartrejected;
          }else{
            this.rejectionData =[]
            this.filteredData =[]
          }
        },
        (error) => {
          this.rejectionData = [];
        }
      );
    this.subscriptions.push(subscription);
  }

  filterData() {
    if (this.searchTerm2) {
      this.filteredData = this.rejectionData.filter((item) =>
        item.partId.toLowerCase().includes(this.searchTerm2.toLowerCase())
      );
    } else {
      this.filteredData = this.rejectionData;
    }
  }
  filterSampleData() {
    if (this.searchRecord) {
      this.filteredsampleData = this.sampleData.filter((item) =>
        item.program.toLowerCase().includes(this.searchRecord.toLowerCase())
      );
    } else {
      this.filteredsampleData = this.sampleData;
    }
  }

  LiveChart(params) {
    this.gattchart(params);
    this.getPartCount(params);
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  onPageChange1(pageNum: number): void {
    this.pageSize1 = this.itemsPerPage1 * (pageNum - 1);
  }
  onPageChange2(pageNum: number): void {
    this.pageSize2 = this.itemsPerPage2 * (pageNum - 1);
  }
  isToday:boolean=true
  select_Date(e) {
    if(moment().format('YYYY-MM-DD') == e){
      this.isToday=true
    }else{
      this.isToday=false
    }
    this.startDate = e;
    // this.getPartData();
    // this.getRecords();
    this.getData();
    // this.getRejectionData();
    // this.getDowntimeData();
  }
  select_Shift(e) {
    this.selectedShifts = e;
    console.log(this.selectedShifts)
    let data = this.shiftsArr.filter((val) => {
      if (val.usershiftId == e) {
        return val;
      }
    });
    this.selectshift=data;
    this.shiftId = data[0]["shiftId"];
    // this.getPartData();
    // this.getRejectionData();
    // this.getDowntimeData();
    this.getData()
  }
  gettimestamp(data) {
    let starttime, endtime;
  
    if (data[0]['startNextDayFlag'] === false && data[0]['endNextDayFlag'] === true) {
      starttime = moment(`${this.startDate} ${data[0]['startTime']}`, "YYYY-MM-DD HH:mm:ss:SSS").valueOf();
      endtime = moment(`${this.startDate} ${data[0]['endTime']}`, "YYYY-MM-DD HH:mm:ss:SSS").add(1, 'days').valueOf();
    } else if (data[0]['startNextDayFlag'] === true && data[0]['endNextDayFlag'] === false) {
      starttime = moment(`${this.startDate} ${data[0]['startTime']}`, "YYYY-MM-DD HH:mm:ss:SSS").add(1, 'days').valueOf();
      endtime = moment(`${this.startDate} ${data[0]['endTime']}`, "YYYY-MM-DD HH:mm:ss:SSS").add(1, 'days').valueOf();
    } else if (data[0]['startNextDayFlag'] === false && data[0]['endNextDayFlag'] === false) {
      starttime = moment(`${this.startDate} ${data[0]['startTime']}`, "YYYY-MM-DD HH:mm:ss:SSS").valueOf();
      endtime = moment(`${this.startDate} ${data[0]['endTime']}`, "YYYY-MM-DD HH:mm:ss:SSS").valueOf();
    }
  
    return { starttime, endtime };
  }
  
  select_Part(e) {
    this.selectedPart = e;
    this.getRejectionData();
  }

  updateTime() {
    this.currentTime = new Date();
  }
  startTimer() {
    this.addRecord = false;
    this.deactivate = !this.deactivate;
    sessionStorage.setItem("Deactivate", "false");
    this.timerRunning = true;
    this.startTime = this.converdata(this.currentTime);
  }
  converdata(dateString) {
    const date = new Date(dateString);
    const hours = ("0" + date.getHours()).slice(-2); // Extract hours and ensure two digits
    const minutes = ("0" + date.getMinutes()).slice(-2); // Extract minutes and ensure two digits
    const seconds = ("0" + date.getSeconds()).slice(-2); // Extract seconds and ensure two digits
    return hours + ":" + minutes + ":" + seconds;
  }
  stopTimer() {
    this.addRecord = true;
    clearInterval(this.timer);
    this.timerRunning = false;
    this.currentTime = new Date();
    this.endTime = this.converdata(this.currentTime);
    this.duration = this.getTimeDifference(this.startTime, this.endTime);
    this.showfields = !this.showfields;
    this.validationform.get("program").disable();
  }
  getTimeDifference(time1, time2) {
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);
    const milliseconds1 = (hours1 * 3600 + minutes1 * 60 + seconds1) * 1000;
    const milliseconds2 = (hours2 * 3600 + minutes2 * 60 + seconds2) * 1000;
    const diffMilliseconds = Math.abs(milliseconds2 - milliseconds1);
    const hours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);
    const formattedHours = ("0" + hours).slice(-2);
    const formattedMinutes = ("0" + minutes).slice(-2);
    const formattedSeconds = ("0" + seconds).slice(-2);
    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  }
  openModalrecoard(contentTemp) {
    this.modalService.open(contentTemp, { backdrop: "static", centered: true });
  }
  openModalsample(contentTemp) {
    this.modalService.open(contentTemp, { backdrop: "static", centered: true });
  }
  openModaladdrecoard(contentTemp, data) {
    this.action = data;
    this.modalService.open(contentTemp, { backdrop: "static", centered: true });
  }
  openrejectionReason(contentTemp) {
    if (this.showModel) {
      this.modalService.open(contentTemp, {
        backdrop: "static",
        centered: true,
      });
    }
  }

  initalizeform() {
    this.validationform = this.formBuilder.group({
      program: ["", [Validators.required]],
    });
  }
  generateRandomName = (length) =>
    Array.from(
      { length },
      () =>
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"[
          Math.floor(Math.random() * 52)
        ]
    ).join("");

  saveDatas() {
    this.validationform.get("program").enable();
    let data = {};
    if (this.action == "Add") {
      this.sampleName = this.generateRandomName(8);
      data["sample"] = this.sampleName;
      data["program"] = this.validationform.value["program"];
      data["custId"] = this.userData["user"]["custId"];
      data["startTime"] = this.epoch(this.startTime);
      data["deviceId"] = this.selecteMachineId;
      data["deviceName"] = this.selectedMachine;
      data["operatorEmail"] = this.userData["user"]["email"];
      data["shiftId"] = this.userData["user"]["currentShift"]["shiftId"];
      data["date"] = moment(
        this.userData["user"]["currentShift"]["start"]
      ).format("DD-MM-YYYY HH:mm:ss");
    } else {
      this.submitted = true;
      data["sample"] = this.sampleName;
      data["program"] = this.validationform.value["program"];
      data["custId"] = this.userData["user"]["custId"];
      data["startTime"] = this.epoch(this.startTime);
      data["endTime"] = this.epoch(this.endTime);
      data["duration"] = this.duration;
      data["deviceId"] = this.selecteMachineId;
      data["deviceName"] = this.selectedMachine;
      data["operatorEmail"] = this.userData["user"]["email"];
      data["shiftId"] = this.userData["user"]["currentShift"]["shiftId"];
      data["date"] = moment(
        this.userData["user"]["currentShift"]["start"]
      ).format("DD-MM-YYYY HH:mm:ss");
    }

    if (this.validationform.status === "VALID") {
      // if(this.action != "Add"){
        this.dataservice.post("api/addsample", data).subscribe((res) => {
          if (res) {
            this.toastr.success("Sample record Added Successfully");
            if (this.action != "Add") {
              this.endTime = "";
              this.startTime = "";
              this.duration = "";
              this.sampleName = "";
              this.submitted = false;
              this.deactivate = true;
              sessionStorage.setItem("Deactivate", "true");
              this.showfields = false;
              this.close();
              this.getRecords();
            } else if (this.action == "Add") {
              this.closeAdd();
            }
          }
        });
      // } 
    } else {
      return this.validationform.controls;
    }
  }
  get form() {
    return this.validationform.controls;
  }
  epoch(time) {
    let currentDate = new Date();
    let [hours, minutes, seconds] = time.split(":").map(Number);
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(seconds);
    let epochTime = currentDate.getTime();
    return epochTime;
  }

  formatTime(data: any) {
    return data ? moment(data).format("HH:mm:ss") : moment().format("HH:mm:ss");
  }

  close() {
    this.modalService.dismissAll();
    this.deactivate = true;
    sessionStorage.setItem("Deactivate", "true");
    this.hideMachine = false;
    this.submitted = false;
    this.startTime = "";
    this.endTime = "";
    this.duration = "";
    this.validationform.get("program").enable();
    this.showfields = false;
  }
  close1() {
    this.modalService.dismissAll();
    this.timerRunning = false;
    this.deactivate = true;
    this.startTime = "";
    this.endTime = "";
    this.duration = "";
    this.addRecord = true;
    sessionStorage.setItem("Deactivate", "true");
  }
  closeAdd() {
    this.modalService.dismissAll();
    this.action = false;
    this.getRecords();
  }
  showNavigationNotAllowedToast() {
    this.toastr.error(
      "You are not allowed to navigate away.",
      "Permission Denied"
    );
  }

  selectType($event) {
    this.selectedType = $event.target.value;
    this.typeData = this.downData.filter(
      (val) => val["name"] == this.selectedType
    );
    if (this.typeData.length) {
      this.orderedData = this.sortedTypeData(this.typeData);
    }else{
      this.orderedData=[]
    }
  }
  checkCount(val) {
    return val ? val : 0;
  }
  canDeactivate(): boolean {
    if (!this.deactivate) {
      this.showNavigationNotAllowedToast();
    } else {
      return this.deactivate;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
