import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
// import * as echarts from "echarts/core";
import * as echarts from "echarts";

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts";
import { CustomChart } from "echarts";
import { CanvasRenderer } from "echarts";
@Component({
  selector: "app-newganttchart",
  templateUrl: "./newganttchart.component.html",
  styleUrls: ["./newganttchart.component.scss"],
})

/**
 * E-chart component
 */
export class NewganttchartComponent implements OnInit, AfterViewInit {
  // @ViewChild("ganttChart") ganttChartElement: ElementRef;
  @Input() gaugeArray: any;

  ganttChart: any = [];
  data = [
    {
      id: "675b7e4080c6e36bfcc9f763",
      fromTo: "06:00:00-06:57:21",
      name: "Idle",
      value: [0, 1734049800000, 1734053241000, 3441],
      reason: "General",
      subReason: "NOT SPECIFIED",
      reasonCode: "100",
      subreasonCode: "1000",
      itemStyle: {
        normal: {
          color: "#f8fb20",
        },
      },
    },
    {
      id: "675b8d7980c6e36bfcc9fc03",
      fromTo: "06:57:21-07:00:06",
      name: "Running",
      value: [0, 1734053241000, 1734053406000, 165],
      reason: "General",
      subReason: "NOT SPECIFIED",
      reasonCode: "100",
      subreasonCode: "1000",
      itemStyle: {
        normal: {
          color: "#55ff33",
        },
      },
    },
  ];
  echartsInstance: any;
  constructor(private elementRef: ElementRef) {}

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];
    if (this.gaugeArray.length) {
      this.ganttChart = this.gaugeArray;
      console.log(this.ganttChart,"this.ganttChart" )
      this.plotGanttchart();
    }

    // var data = {
    //   time: this.gaugeArray[0]["time"],
    //   value: this.gaugeArray[1]["overall_status"],
    // };
    // if (this.gaugeArray[0]["time"][0].length != 0) {
    //   var data = {
    //     time: this.gaugeArray[0]["time"],
    //     value: this.gaugeArray[1]["overall_status"],
    //   };
    //   // this.chartData(data);
    //   // this.gaugeChart(this.data);
    // // }
    // console.log(this.gaugeArray, data);
    // this.chartData();
  }
  option: any;
  ngOnChanges(): void {
    console.log(
      // this.gaugeArray[0]["time"][0],
      this.gaugeArray,
      "resss.............."
    );
    if (this.gaugeArray.length) {
      this.ganttChart = this.gaugeArray;
      this.plotGanttchart();
    }
    // if (this.gaugeArray[0]["time"][0].length != 0) {
    //   var data = {
    //     time: this.gaugeArray[0]["time"],
    //     value: this.gaugeArray[1]["overall_status"],
    //   };
    //   console.log(data, "resss..............");
    //   // this.chartData(data);

    //   // this.gaugeChart(this.gaugeArray);
    //   // this.gaugeChart(this.data);
    // }
  }
  ngAfterViewInit(): void {}
  plotGanttchart() {
    console.log(this.ganttChart, "data");
    // let myChart = echarts.init(this.ganttChartElement.nativeElement);
    var startTime = this.ganttChart[0]?.value[1];
    var lastDataTimestamp =
      this.ganttChart[this.ganttChart.length - 1]?.value[2];

    function formatTime(timestamp) {
      const date = new Date(timestamp);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return hours + ":" + minutes + ":" + seconds;
    }

    function renderItem(params, api) {
      var categoryIndex = api.value(0);
      var start = api.coord([api.value(1), categoryIndex]);
      var end = api.coord([api.value(2), categoryIndex]);
      var height = api.size([0, 1])[1] * 0.6;
      var rectShape = echarts.graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height,
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
        }
      );
      return (
        rectShape && {
          type: "rect",
          transition: ["shape"],
          shape: rectShape,
          style: api.style(),
        }
      );
    }

    this.option = {
      tooltip: {
        trigger: "item",
        backgroundColor: "#000000",
        formatter: function (params) {
          var data = params.data;
          var statusColor =
            data.name === "Running"
              ? "#77ff5c"
              : data.name === "Idle"
              ? "#f9fc4d"
              : data.name === "Off"
              ? "blue"
              : data.name === "Standby"
              ? "#f54c4c"
              : data.name === "Disconnect"
              ? "#b0b0b0"
              // : data.name === "Nodata"
              // ? "#CB9DF0"
              : data.name === "Breakdown"
              ? "#eb5857"
              : "grey";
          var tooltipContent =
            '<div style="text-align:left;">' +
            '<p><strong>Status : <span style="color:' +
            statusColor +
            ';">' +
            data.name +
            "</strong></p>" +
            "<p><strong>From-To : </strong> " +
            data.fromTo +
            "</p>" +
            "<p><strong>Duriation : </strong> " +
            data.value[3] +
            "</p>";
          ("</div>");
          return tooltipContent;
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zooming",
              back: "Back",
            },
          },
          restore: {
            title: "Restore",
          },
        },
      },

      dataZoom: [
        {
          type: "slider",
          // filterMode: "weakFilter",
          showDataShadow: true,
          top: 400,
          // labelFormatter: "",
          labelFormatter: function (value, valueStr) {
            console.log(value, valueStr, "value---------------:::::");
            return moment(value).utc().format("HH:mm:ss");
          },
        },
        {
          labelFormatter: function (value, valueStr) {
            console.log(value, valueStr, "value---------------:::::");
            return moment(value).utc().format("HH:mm:ss");
          },
          // type: "inside",
          // filterMode: "weakFilter",
        },
      ],
      xAxis: {
        min: startTime,
        max: lastDataTimestamp,
        scale: true,
        axisLabel: {
          lineStyle: {
            color: "#151515",
            width: 2,
          },
          formatter: function (val) {
            return moment(val).utc().format("HH:mm:ss"); // Customize the format as needed
          },
        },
        // name:'Time',
        // nameLocation: "center",
      },
      yAxis: {
        show: false,
        data: ["Machine 1"],
        axisLine: {
          lineStyle: {
            color: "#151515",
            width: 2,
          },
          show: true,
        },
      },
      series: [
        {
          type: "custom",
          renderItem: renderItem,
          itemStyle: {
            opacity: 0.8,
          },
          encode: {
            x: [1, 2],
            y: 0,
          },
          data: this.ganttChart,
        },
      ],
    };
  }
  hms(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Pad single-digit minutes and seconds with leading zeros
    return (
      String(hours).padStart(2, "0") +
      ":" +
      String(minutes).padStart(2, "0") +
      ":" +
      String(remainingSeconds).padStart(2, "0")
    );
  }
}
