<div class="container-fluid" >
  <div class="row d-flex justify-content-between">
    <div class="col-md-6 d-flex">
      <select class="col-md-6 form-control custom-select" (change)="onMachineChange($event)" name="machineName"
        id="machineName" [disabled]="!addRecord" [(ngModel)]="selectedMachine">
        <option *ngFor="let device of machine_List" [value]="device.deviceName">
          {{ device.deviceName }}
        </option>
      </select>
      <i *ngIf="showData" style="
            cursor: pointer;
            margin-left: 5px;
            display: flex;
            align-items: center;
          " (click)="openModal(shifttemplate)" class="ri-information-fill font-size-14"></i>
    </div>
    <div class="col-md-6" style="display: flex; justify-content: right">
      <!-- <div class="col-md-4"> -->
      <div *ngIf="showData" class="spinner-grow m-1" role="status" [style.color]="statusColor(status)">
        <span class="sr-only">Loading...</span>
      </div>
      <h6 *ngIf="showData" style="display: flex; justify-content: center; align-items: center">
        {{ epochToHHMMSS(LUT) }}
      </h6>
      &nbsp;&nbsp;
      <!-- <ng-select class="col-md-4" [disabled]="isDisabled" [(ngModel)]="selectedCars" (change)="selectedShift($event)">
        <ng-option [value]="'All Shift'" selected>All Shift</ng-option>
        <ng-option *ngFor="let data of shiftsArr" [value]="data.usershiftId">{{
          data.shiftName
          }}</ng-option>
      </ng-select> -->
      <input [disabled]="!addRecord" type="date" class="form-control col-md-3" [max]="maxDate" [min]="minDate" [(ngModel)]="startDate"
                  placeholder="From Date" name="startDate" id="startDate" (ngModelChange)="select_Date($event)" />
      <!-- </div> -->
    </div>

  </div>
<div *ngIf="showData">

  <div class="row mt-1">
    <div [ngClass]="role === 'SUPERADMIN' ? 'col-md-12' : 'col-md-10'">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="row">
                    <div class="col-md-2">
                      <h6 class="text-primary">M-Efficiency</h6>
                      <h5 class="mb-0">
                        {{ m_effic ? m_effic : 0 }}
                        <span style="font-size: 1rem !important">% </span>
                      </h5>
                    </div>
                    <div class="col-md-2">
                      <h6 class="text-primary">M-utilisation</h6>
                      <h5 class="mb-0">
                        {{ perf ? perf : 0 }}
                        <span style="font-size: 1rem !important">% </span>
                      </h5>
                    </div>
                    <div class="col-md-2">
                      <h6 class="text-primary">Running</h6>
                      <h5 class="mb-0">
                        {{ houers("running") }}
                      </h5>
                    </div>
                    <div class="col-md-2">
                      <h6 class="text-primary">Idle</h6>
                      <h5 class="mb-0">
                        {{ houers("idle") }}
                      </h5>
                    </div>
                    <div class="col-md-2">
                      <h6 class="text-primary">Total Parts[{{ type }}]</h6>
                      <h5 class="mb-0">{{ parts ? parts : 0 }}</h5>
                    </div>
                    <div class="col-md-2">
                      <h6 class="text-primary">
                        Current
                        <i style="cursor: pointer" (click)="openModal(currenttemplate)"
                          class="ri-wireless-charging-fill font-size-14"></i>
                      </h6>
                      <h5 class="mb-0">{{ current ? current : 0 }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="role !== 'SUPERADMIN'">
      <div class="card">
        <div class="d-flex justify-content-around align-items-center">
          <p class="text-truncate text-center mb-1 mt-2"><b>Sample Records</b></p>
          <i style="cursor: pointer" (click)="openModalsample(sampleTemp)"
            class=" ri-file-list-3-fill font-size-13 mt-1"></i>
        </div>

        <div class="form-group " *ngIf="!showfields">
          <div class="d-flex justify-content-around" style="height: 40px;">
            <div>
              StartTime
              <p *ngIf="timerRunning">{{startTime}}</p>
              <p *ngIf="!timerRunning">--:--:--</p>
            </div>
            <button #candeactivate [disabled]="!isToday" type="button" class="btn btn-success jobs" *ngIf="!timerRunning"
              (click)="startTimer(); openModaladdrecoard(contentTemp2, 'Add')">
              Start
            </button>
            <button type="button" class="btn btn-danger jobs" *ngIf="timerRunning"
              (click)="stopTimer(); openModalrecoard(contentTemp)">
              Stop
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-xl-12">

      <div class="card">
        <div class="card-body">

          <div class="row">
            <!-- <div class="col-md-2">
              <div class="form-group">
                <label>Date</label>
                <input type="date" class="form-control" [max]="maxDate" [min]="minDate" [(ngModel)]="startDate"
                  placeholder="From Date" name="startDate" id="startDate" (ngModelChange)="select_Date($event)" />
              </div>
            </div> -->
            <div class="col-md-2">
              <div class="form-group">
                <label>Shift</label>
                <select class="form-control custom-select" (ngModelChange)="select_Shift($event)" name="shiftName"
                  id="shiftName" ngModel [(ngModel)]="selectedShifts">
                  <option *ngFor="let el of shiftsArr" [ngValue]="el.usershiftId">
                    {{ el.shiftName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Part Name</label>
                <select class="form-control custom-select" (ngModelChange)="select_Part($event)" name="partName"
                  id="partName" ngModel [(ngModel)]="selectedPart">
                  <option *ngFor="let e of partData" [ngValue]="e.partName">
                    {{ e.partName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div>
                <label>PartProduced</label>
                <input class="form-control" disabled ngModel [(ngModel)]="selectedPartproduced"
                  (ngModelChange)="checkCount(selectedPartproduced)" />
              </div>
            </div>
            <div class="col-md-2">
              <div>
                <label>Part Rejected</label>
                <input class="form-control" disabled ngModel [(ngModel)]="selectedPartrejected"
                  (ngModelChange)="checkCount(selectedPartrejected)" />
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6 col-xl-6">
              <div class="card">
                <div class="card-body">
                  <div class="row d-flex justify-content-between">
                    <div>
                      <h5>Downtime Entry</h5>
                    </div>
                    <div class="d-flex">

                      <input class="form-control" style="width: 100px;" placeholder="Search" type="text"
                        autocomplete="off" [(ngModel)]="searchTerm" name="search"
                        id="table-complete-search" />&nbsp;&nbsp;
                      <select class="form-control custom-select" style="width: 100px;" (change)="selectType($event)" name="downtimme"
                        id="downtimme" ngModel [(ngModel)]="selectedType">
                        <option *ngFor="let e of downtimeType" [value]="e">
                          {{ e }}
                        </option>
                      </select>
                    </div>
                    
                  </div>

                  <div class="table-responsive mt-1">
                    <table class="table table-sm table-centered datatable dt-responsive nowrap"
                      style="border-collapse: collapse; border-spacing: 0; width: 100%">
                      <thead class="thead-light">
                        <tr>
                          <th>From [HH:MM:SS]</th>
                          <th>To [HH:MM:SS]</th>
                          <th>Reason</th>
                          <th>SubReason</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr (click)="opendowntimeReason(downtimeTemp,item)" *ngFor="
                              let item of orderedData
                                | filter : searchTerm
                                | slice : pageSize2
                                | slice : 0 : itemsPerPage2
                              let i = index
                            ">
                          <td>{{ item.fromTo.split('-')[0].split(".")[0] }}</td>
                          <td>{{ item.fromTo.split('-')[1].split(".")[0] }}</td>
                          <td>{{ item.reasonData['reason'] }}</td>
                          <td>{{ item.reasonData['subReason'] }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div>
                      <ngb-pagination class="pagination-rounded page2" [collectionSize]="orderedData?.length" #numPages2
                        [pageSize]="itemsPerPage2" [(page)]="currentPage2" [maxSize]="1" [rotate]="true"
                        [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChange2(currentPage2)">
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6">
              <div class="card">
                <div class="card-body">
                  <div class="row d-flex justify-content-between">
                    <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                      <div style="
                          display: flex;">
                        <h5>Rejection Entry</h5>&nbsp;&nbsp;&nbsp;&nbsp;
                        <div style="display: flex; margin-left: 10px;">

                          <select class="form-control custom-select" (change)="selectChoice($event)">
                            <option value="single">Single</option>
                            <option value="multiple">Multiple</option>
                          </select>&nbsp;&nbsp;&nbsp;&nbsp;
                          <input *ngIf="!singlebool" type="number" style="width: 100px;" id="count" name="count"
                            class="form-control" [(ngModel)]="searchCount" (input)="getCount()" placeholder="Count">
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <input class="form-control" style="width: 100px;" placeholder="Search" type="text"
                        autocomplete="off" [(ngModel)]="searchTerm2" (input)="filterData()" name="search"
                        id="table-complete-search" />
                      &nbsp; &nbsp; &nbsp;
                      <button class="btn btn-primary" type="button" (click)="getRejectionData()">
                        <i class="fas fa-sync sync-icon">
                        </i>
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive mt-1">
                    <table class="table table-sm table-centered datatable dt-responsive nowrap"
                      style="border-collapse: collapse; border-spacing: 0; width: 100%">
                      <thead class="thead-light">
                        <tr>
                          <th *ngIf="!singlebool">Select</th>
                          <th>Part Id</th>
                          <!-- <th>Time</th> -->
                          <th>Reason</th>
                          <th>Rejected</th> 
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="
                              let item of filteredData
                                | slice : pageSize
                                | slice : 0 : itemsPerPage;
                              let i = index
                            ">
                          <td *ngIf="!singlebool">
                            <input id="dd" type="checkbox" [checked]=checkflag(item.flag) (click)="toggleFlag(item)" />
                          </td>
                          <td>{{ item.partId }}</td>
                          <!-- <td>{{ item.time }}</td> -->
                          <td>
                            <select class="reject" (change)="onReason(item)" [disabled]='!singlebool'
                              name="rejectReason" id="rejectReason" [(ngModel)]="item.Reason">
                              <option value="" selected>Select Reason</option>
                              <option *ngFor="let data of rejectionReasons" [value]="data.reason">
                                {{ data.reason }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <span [ngClass]="{
                                  'badge badge-danger': item.isRejected,
                                  'badge badge-success': !item.isRejected
                                }">
                              {{ item.isRejected ? "Rejected" : "Pass" }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between">
                      <ngb-pagination class="pagination-rounded" [collectionSize]="filteredData?.length" #numPages
                        [pageSize]="itemsPerPage" [(page)]="currentPage" [maxSize]="1" [rotate]="true"
                        [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChange(currentPage)">
                      </ngb-pagination>
                      <button class="btn btn-primary" *ngIf="!singlebool" type="button"
                        (click)="openrejectionReason(rejectionTemp)">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showCombined">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">Live Gantt Chart</h4>
            </div>
            <div class="col-md-6 text-md-right">
              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #f7c030; color: white">
                  Idle
                </p>
              </div>
              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #ffff94">Dryrun</p>
              </div>
              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #548237; color: white">
                  Running
                </p>
              </div>
              <div class="d-inline-block mr-2" (click)="LiveChart(params)" *ngIf="!intervalFlag">
                <p class="badge">
                  <i class="ri-refresh-line align-middle mr-2"></i>Refresh
                </p>
              </div>

              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: white; color: black">
                  No Data
                </p>
              </div>
            </div>
          </div>
          <app-combinechart *ngIf="showChart" [gaugeArray]="finalcombinedData"></app-combinechart>

          <div *ngIf="!showChart">
            <img src="assets/images/noData.jpg" width="100%" />
            Please wait we are fetching data / nodata
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [hidden]="ganttBool">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">
                <select [(ngModel)]="selectedDateOption" (change)="historyChart()">
                  <option value="Live">Today</option>
                  <option *ngFor="let data of previousDays" [value]="data">
                    {{ data }}
                  </option>
                </select>
                Gantt Chart
              </h4>
            </div>

            <div class="col-md-6 text-md-right">
              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #f7c030; color: white">
                  Idle
                </p>
              </div>

              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #548237; color: white">
                  Running
                </p>
              </div>
              <div class="d-inline-block mr-2" *ngIf="intervalFlag" (click)="LiveChart(params)">
                <p class="badge" style="background-color: #375e82; color: white">
                  <i class="ri-refresh-line align-middle mr-2"></i> Refresh
                </p>
              </div>
            </div>
          </div>

          <app-newganttchart *ngIf="showChart" [gaugeArray]="historyDatas"></app-newganttchart>
        </div>
        <div *ngIf="!showChart">
          <img src="assets/images/noData.jpg" width="100%" />
          Please wait we are fetching data / nodata
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="mt-3 d-flex justify-content-center align-items-center text-center" *ngIf="!showData">Machines are not planned on this Day. To view data, Map the operator to the Machine. </div>
<ng-template #currenttemplate role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Current Trend</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="row">
      <div class="col-md-12">
        <app-energychart [gaugeArray]="finalcombinedData"></app-energychart>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #sampleTemp role="document" let-modal="close">
  <div class="modal-header">
    <div class="d-flex justify-content-center align-items-center">
      <h5 class="modal-title">Records</h5> &nbsp;&nbsp;
      <input class="form-control search__input" placeholder="Search" type="text" autocomplete="off"
        [(ngModel)]="searchRecord" (input)="filterSampleData()" name="searchRecord" id="searchRecord" />
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <!-- <div class="col-md-6"> -->
    <!-- <div class="card">
          <div class="card-body"> -->
    <div class="table-responsive" *ngIf="filteredsampleData?.length != 0">
      <table class="table table-centered datatable dt-responsive nowrap"
        style="border-collapse: collapse; border-spacing: 0; width: 100%">
        <thead class="thead-light">
          <tr>
            <th>Part Name</th>
            <th>Form-To [HH:MM:SS]</th>
            <th>Duration [HH:MM:SS]</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
                      let data of filteredsampleData
                        | slice : pageSize1
                        | slice : 0 : itemsPerPage1;
                      let i = index
                    ">
            <td>{{ data.program }}</td>
            <td>
              {{ formatTime(data.startTime) }}-{{
              formatTime(data.endTime)
              }}
            </td>
            <td>{{ data.duration }}</td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-12" style="display: flex; justify-content: space-between">
        <div>
          <ngb-pagination class="pagination-rounded page" [collectionSize]="filteredsampleData?.length" #numPages1
            [pageSize]="itemsPerPage1" [(page)]="currentPage1" [maxSize]="1" [rotate]="true" [ellipses]="false"
            [boundaryLinks]="true" (pageChange)="onPageChange1(currentPage1)">
          </ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="filteredsampleData?.length == 0" class="text-center">
      <img src="assets/images/noData.jpg" class="imgs" />
      Please wait we are fetching data / nodata
    </div>
    <!-- </div>
        </div> -->
    <!-- </div> -->
  </div>
</ng-template>
<ng-template #shifttemplate role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Shift Details</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <div class="row">
                  <div class="col-md-3 text-center" *ngFor="let shift of shiftsArr">
                    <div [ngClass]="{
                          'current-shift':
                            currentShift &&
                            currentShift.shiftName === shift.shiftName
                        }">
                      <p>{{ shift.shiftName }}</p>
                      <h4>{{ shift.startTime }} - {{ shift.endTime }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentTemp role="document" let-modal="close">
  <form (ngSubmit)="saveDatas()" [formGroup]="validationform" autocomplete="off">
    <div class="container">
      <div class="modal-header">
        <h5 class="modal-title">Sample Recard</h5>
        <button type="button" [disabled]="!addRecord" class="close" aria-label="Close" (click)="modal('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <div class="row d-flex justify-content-center" *ngIf="showfields">
          <div class="col-md-6">
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-md-6">
                <label for="startTime"><b>Start Time</b></label>
                <div>{{ startTime }}</div>
              </div>
              <div class="col-md-6">
                <label for="endTime"><b>End Time</b></label>
                <div>{{ endTime }}</div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <label for="duration"><b>Duration</b></label>
                <div>{{ duration }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label for="program">Program Name</label>
                  <input type="text" class="form-control" id="program" placeholder="Enter program"
                    formControlName="program" [ngClass]="{
                        'is-invalid': submitted && form.program.errors
                      }" />
                  <div *ngIf="submitted && form.program.errors" class="invalid-feedback">
                    <span *ngIf="form.program.errors.required">Program Name is mandatory.</span>
                  </div>
                </div>
                <div class="text-right">
                  <button type="button" class="btn btn-success" (click)="saveDatas()">
                    Submit
                  </button>
                  &nbsp;
                  <button type="button" class="btn btn-secondary" (click)="close(); modal('Cross click')">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #contentTemp2 role="document" let-modal="close">
  <form (ngSubmit)="saveDatas()" [formGroup]="validationform" autocomplete="off">
    <div class="container">
      <div class="modal-header">
        <h5 class="modal-title">Sample Recard</h5>
        <button type="button" [disabled]="!addRecord" class="close" aria-label="Close" (click)="modal('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <div class="d-flex justify-content-between">
          <div class="form-group col-md-6">
            <label for="program">Program Name</label>

            <select class="form-control" id="program" formControlName="program" [ngClass]="{
                  'is-invalid': submitted && form.program.errors
                }">
              <option value="" disabled selected>Select a Part</option>
              <option *ngFor="let part of partData" [value]="part.partName">
                {{ part.partName }}
              </option>
            </select>
            <div *ngIf="submitted && form.program.errors" class="invalid-feedback">
              <span *ngIf="form.program.errors.required">Program Name is mandatory.</span>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button type="button" class="btn btn-success" (click)="saveDatas()">
            Submit
          </button>
          &nbsp;
          <button type="button" class="btn btn-secondary" (click)="close1(); modal('Cross click')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #rejectionTemp role="document" let-modal="close">
  <div class="modal-header">
    <div>
      <h5 class="modal-title">Rejection</h5> &nbsp;&nbsp;
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="d-flex justify-content-between">
      <div class="form-group col-md-6">
        <label for="rejectReason2">Select Reason</label>
        <select class="form-control" (change)="onReason2($event)" name="rejectReason2" id="rejectReason2"
          [(ngModel)]="selectedRejection">
          <option value="" disabled selected>Select Reason</option>
          <option *ngFor="let data of rejectionReasons" [value]="data.reason">
            {{ data.reason }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6 d-flex justify-content-center align-items-center">
        <div>
          <button class="btn btn-primary" type="submit" (click)="multiReason()">Save</button>
        </div>&nbsp;
        <button type="button" class="btn btn-secondary" (click)="multiReasonclose(); modal('Cross click')">
          Cancel
        </button>

      </div>
    </div>
  </div>
</ng-template>
<ng-template #downtimeTemp role="document" let-modal="close">
  <div class="modal-header">
    <div>
      <h5 class="modal-title">Downtime</h5> &nbsp;&nbsp;
    </div>
    <button type="button" class="close" aria-label="Close" (click)="multidownclose();modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="d-flex justify-content-between">

      <div class="row justify-content-start">
        From - To : {{downtimeData['fromTo'].split('-')[0].split('.')[0]}} -
        {{downtimeData['fromTo'].split('-')[1].split('.')[0]}}
      </div>
      <div class="row justify-content-end" (click)="AddSubReason()">
        <i *ngIf="addBool" [hidden]="isSame" class="mdi mdi-plus-circle mr-2" style="color: '#1bcfb4'"></i>
      </div>
    </div>
    <table class="table responsive">
      <thead class="bold-header">
        <tr>
          <th *ngIf="!isSame" class="col-start-time">Start Time</th>
          <th *ngIf="!isSame" class="col-end-time">End Time</th>
          <th class="col-reason">Reason</th>
          <th class="col-subreason">Sub-Reason</th>
          <th *ngIf="!isSame" class="col-action">Action</th>
        </tr>
      </thead>
      <tbody *ngIf="downtimevalues.length > 0">
        <tr *ngFor="let data of downtimevalues; let i = index">
          <td *ngIf="!isSame">
            <input type="text" class="form-control form-control-sm" name="startTime{{i}}" disabled id="startTime{{i}}"
              ngModel [(ngModel)]="data.startTime" />
          </td>
          <td *ngIf="!isSame">
            <select name="endTime{{i}}" id="endTime{{i}}" class="form-control form-control-sm" ngModel
              [(ngModel)]="data.endTime" (change)=changeTime($event,i) [disabled]="i !== downtimevalues.length - 1">
              <option value="" disabled selected>Select End Time</option>
              <!-- <option *ngFor="let val of endtimeData" [value]="val">{{ val }}</option> -->
              <option *ngFor="let val of endtimeData[i]" [value]="val">{{ val }}</option>
            </select>
          </td>
          <td *ngIf="!isSame">
            <select name="mainReason{{i}}" id="mainReason{{i}}" class="form-control form-control-sm" ngModel
              [(ngModel)]="data.reason" (change)=changeReason($event.target.value,i) [disabled]="(i !== downtimevalues.length - 1) || timeBool">
              <option value="" disabled selected>Select Reason</option>
              <option *ngFor="let reason of downtimeReasons" [value]="reason['reason']">
                {{ reason['reason'] }}
              </option>
            </select>
          </td>
          <td *ngIf="isSame">
            <select name="mainReason{{i}}" id="mainReason{{i}}" class="form-control form-control-sm" ngModel
              [(ngModel)]="data.reason" (change)=changeReason($event.target.value,i)>
              <option value="" disabled selected>Select Reason</option>
              <option *ngFor="let reason of downtimeReasons" [value]="reason['reason']">
                {{ reason['reason'] }}
              </option>
            </select>
          </td>
          <td *ngIf="!isSame">
            <select name="subreason{{i}}" id="subreason{{i}}" class="form-control form-control-sm" ngModel
              [(ngModel)]="data.subReason" (change)=changesubReason($event,i)
              [disabled]="(i !== downtimevalues.length - 1) || timeBool">
              <option value="" disabled selected>Select Sub-Reason</option>
              <option *ngFor="let subreason of subReasonData[i]" [value]="subreason['subReason']">
                {{ subreason['subReason'] }}
              </option>
            </select>
          </td>
          <td *ngIf="isSame">
            <select name="subreason{{i}}" id="subreason{{i}}" class="form-control form-control-sm" ngModel
              [(ngModel)]="data.subReason" (change)=changesubReason($event,i)
              >
              <option value="" disabled selected>Select Sub-Reason</option>
              <option *ngFor="let subreason of subReasonData[i]" [value]="subreason['subReason']">
                {{ subreason['subReason'] }}
              </option>
            </select>
          </td>
          <td *ngIf="!isSame" class="text-center align-middle">
            <i *ngIf="i === downtimevalues.length - 1" class="mdi mdi-minus-circle mr-1 text-danger"
              (click)="removeRow(i)" style="cursor: pointer"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="form-group col-md-12 d-flex justify-content-center align-items-center">
      <div *ngIf="!isSame">
        <button class="btn btn-primary" [disabled]="timeBool" type="submit" (click)="multidownReason()">Save</button>
      </div>&nbsp;
      <div *ngIf="isSame">
        <button class="btn btn-primary" type="submit" (click)="multidownReason()">Save</button>
      </div>&nbsp;
      <button type="button" class="btn btn-secondary" (click)="multidownclose(); modal('Cross click')">
        Cancel
      </button>

    </div>
  </div>
</ng-template>