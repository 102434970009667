<div class="container-fluid">
  <!-- start page title -->
  <app-pagetitle
    title="Devices"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>
  <!-- end page title -->

  <div class="d-flex justify-content-end" *ngIf="!isEdit">
    <div class="example-button-row">
      <button
        *ngIf="loginRole == 'SUPERADMIN' && machineTypeList.length != 0"
        class="btn btn-primary"
        title="Add"
        (click)="adddevice()"
      >
        Add Device
      </button>
      &nbsp;
      <button
        *ngIf="loginRole == 'SUPERADMIN'"
        class="btn btn-primary"
        title="Add"
        (click)="openModal(machineType, 'Add')"
      >
        Add Machine Type
      </button>
    </div>
  </div>

  <div class="row mt-2" *ngIf="deviceList.length != 0 && !isAdd">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead
                class="table-light text-black text-black"
                style="color: black !important"
              >
                <tr>
                  <th>Machine Name</th>
                  <th>Machine Id</th>
                  <th>Machine Model</th>
                  <th>Machine Type</th>
                  <th>Licensing</th>
                  <th style="width: 120px">Action</th>
                </tr>
              </thead>
              <tbody
                *ngFor="
                  let item of deviceList
                    | slice : pageSize
                    | slice : 0 : itemsPerPage;
                  let i = index
                "
              >
                <tr>
                  <th scope="row">
                    {{ item.deviceName ? item.deviceName : "-" }}
                  </th>
                  <td>{{ item.deviceId ? item.deviceId : "-" }}</td>
                  <td>{{ item.deviceModelNo ? item.deviceModelNo : "-" }}</td>
                  <td>{{ item.deviceType ? item.deviceType : "-" }}</td>
                  <td>{{ item.licensing ? item.licensing : "-" }}</td>
                  <td>
                    <a class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                      <i
                        class="mdi mdi-pencil font-size-18"
                        style="cursor: pointer"
                        (click)="edit(item)"
                      ></i>
                    </a>
                    <a
                      [hidden]="loginRole == 'CUSTOMER'"
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                    >
                      <i
                        class="mdi mdi-trash-can font-size-18"
                        style="cursor: pointer"
                        (click)="openModalDelete(deletetemp, item.id, item)"
                      ></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="deviceList?.length > 5">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="deviceList?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
  <div class="row mt-2" *ngIf="isAdd">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Machine Configuration</h4>
          <form (ngSubmit)="typeSubmit()" [formGroup]="deviceForm">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Device ID</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="deviceId"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.deviceId.errors
                  }"
                  placeholder="348518678A68"
                />
                <div
                  *ngIf="typesubmit && type.deviceId.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.deviceId.errors.required"
                    >This value is required.</span
                  >
                </div>
                <div
                  *ngIf="typesubmit && type.deviceId.errors?.maxlength"
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 12 digits)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Machine Name</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="deviceName"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.deviceName.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.deviceName.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.deviceName.errors.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="deviceType">Machine Type</label>
                <select
                  id="deviceType"
                  class="form-control"
                  formControlName="deviceType"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.deviceType.errors
                  }"
                  (change)="ondeviceTypeChange($event)"
                >
                  <option value="" disabled selected>
                    Select Machine Type
                  </option>
                  <option
                    *ngFor="let devType of machineTypeList; let i = index"
                    [value]="devType"
                  >
                    {{ devType }}
                  </option>
                </select>
                <div
                  *ngIf="typesubmit && type.deviceType.errors"
                  class="invalid-feedback"
                >
                  Please select a machine type.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Machine Model Number</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="deviceModelNo"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.deviceModelNo.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.deviceModelNo.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.deviceModelNo.errors.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Machine Controller</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="deviceController"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.deviceController.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.deviceController.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.deviceController.errors.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Machine Manufacture</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="deviceMake"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.deviceMake.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.deviceMake.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.deviceMake.errors.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Machine Supplier</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="devicesupplier"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.devicesupplier.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.devicesupplier.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.devicesupplier.errors.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Machine Age</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="deviceage"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.deviceage.errors
                  }"
                  placeholder="348"
                />
                <div
                  *ngIf="typesubmit && type.deviceage.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.deviceage.errors.required"
                    >This value is required.</span
                  >
                </div>
                <div
                  *ngIf="
                    typesubmit && deviceForm.get('deviceage').errors?.maxDigits
                  "
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 3 digits)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Current Load [ amps ]</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="current"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.current.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.current.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.current.errors.required"
                    >This value is required.</span
                  >
                </div>
                <div
                  *ngIf="
                    typesubmit && deviceForm.get('current').errors?.maxDigits
                  "
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 3 digits)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Power factor</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="powerfactor"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.powerfactor.errors
                  }"
                  placeholder="345"
                />
                <div
                  *ngIf="typesubmit && type.powerfactor.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.powerfactor.errors.required"
                    >This value is required.</span
                  >
                </div>
                <div
                  *ngIf="
                    typesubmit &&
                    deviceForm.get('powerfactor').errors?.maxDigits
                  "
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 3 digits)
                </div>
              </div>
              <div
                class="form-group col-md-6"
                *ngIf="deviceForm.get('deviceType')['value'] == 'CNC'"
              >
                <label>Number of Axis</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="axis"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.axis.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.axis.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.axis.errors.required"
                    >This value is required.</span
                  >
                </div>
                <div
                  *ngIf="typesubmit && deviceForm.get('axis').errors?.maxDigits"
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 3 digits)
                </div>
              </div>
              <div
                class="form-group col-md-6"
                *ngIf="deviceForm.get('deviceType')['value'] == 'CNC'"
              >
                <label>Number of Spindle</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="spindle"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.spindle.errors
                  }"
                  placeholder="Robo_Welding"
                />
                <div
                  *ngIf="typesubmit && type.spindle.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.spindle.errors.required"
                    >This value is required.</span
                  >
                </div>
                <div
                  *ngIf="
                    typesubmit && deviceForm.get('spindle').errors?.maxDigits
                  "
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 3 digits)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Input Voltage [ unit ]</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="voltage"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.voltage.errors
                  }"
                  placeholder="2kWH"
                />
                <div
                  *ngIf="typesubmit && type.voltage.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.voltage.errors.required"
                    >This value is required.</span
                  >
                </div>
                <div
                  *ngIf="
                    typesubmit && deviceForm.get('voltage').errors?.maxDigits
                  "
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 3 digits)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>OEE Calculation</label>
                <select
                  type="selected"
                  class="form-control"
                  formControlName="oee"
                  [ngClass]="{ 'is-invalid': typesubmit && type.oee.errors }"
                  (change)="onOEEChange($event)"
                >
                  <option value="" disabled selected>Select OEE</option>
                  <option selected value="parts">With Target Part</option>
                  <option value="no_part">With_out Part</option>
                  <option value="cycletime">With_cycleTime</option>
                </select>
                <div *ngIf="typesubmit && type.oee.errors" class="text-danger">
                  Please select a OEE Configuration.
                </div>
              </div>
              <div
                class="form-group col-md-6"
                *ngIf="deviceForm.get('oee')['value'] == 'parts'"
              >
                <label>Target Part</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="targetPart"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.targetPart.errors
                  }"
                  placeholder="345"
                />
                <div
                  *ngIf="typesubmit && type.targetPart.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.targetPart.errors.required"
                    >This value is required.</span
                  >
                </div>
                <!-- <div
                  *ngIf="typesubmit && deviceForm.get('targetPart').errors?.maxDigits"
                  class="invalid-feedback"
                >
                  Maximum length exceeded (maximum: 3 digits)
                </div> -->
              </div>
              <div class="form-group col-md-6">
                <label>Licensing</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="licensing"
                >
                  <option selected value="3">3 months</option>
                  <option value="6">6 months</option>
                  <option value="12">12 months</option>
                  <option value="18">18 months</option>
                  <option value="24">24 months</option>
                  <option value="30">30 months</option>
                  <option value="36">36 months</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Device Manufacture</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="meterSupplier"
                  (change)="onMeterChange($event.target.value)"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.meterSupplier.errors
                  }"
                >
                  <option selected value="Selec">Selec</option>
                  <option value="Multispan">Multispan</option>
                  <option value="Wimera-K2">Wimera-K2</option>
                  <option value="Wimera-Remon">Wimera-Remon</option>
                  <option value="Remon_2024">Remon_2024</option>
                </select>
                <div
                  *ngIf="typesubmit && type.meterSupplier.errors"
                  class="text-danger"
                >
                  Please select a meterSupplier Configuration.
                </div>
              </div>
              <div
                class="form-group col-md-6"
                *ngIf="deviceForm.get('meterSupplier')['value'] == 'Selec'"
              >
                <label>Device Model</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="meterModel"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.meterModel.errors
                  }"
                >
                  <option selected value="EM2M">EM2M</option>
                  <option value="EM4M">EM4M</option>
                </select>
                <div
                  *ngIf="typesubmit && type.meterModel.errors"
                  class="text-danger"
                >
                  Please select a meterModel Configuration.
                </div>
              </div>
              <div
                class="form-group col-md-6"
                *ngIf="deviceForm.get('meterSupplier')['value'] == 'Multispan'"
              >
                <label>Device Model</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="meterModel"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.meterModel.errors
                  }"
                >
                  <option selected value="MFM-13-W">MFM-13-W</option>

                  <option value="AVH-16-W">AVH-16-W</option>
                </select>
                <div
                  *ngIf="typesubmit && type.meterModel.errors"
                  class="text-danger"
                >
                  Please select a meterModel Configuration.
                </div>
              </div>
              <div
                class="form-group col-md-6"
                *ngIf="deviceForm.get('meterSupplier')['value'] == 'Wimera-K2'"
              >
                <label>Device Model</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="meterModel"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.meterModel.errors
                  }"
                >
                  <option selected value="Wim-1010">Wim-1010</option>
                </select>
                <div
                  *ngIf="typesubmit && type.meterModel.errors"
                  class="text-danger"
                >
                  Please select a meterModel Configuration.
                </div>
              </div>
              <div
                class="form-group col-md-6"
                *ngIf="
                  deviceForm.get('meterSupplier')['value'] == 'Wimera-Remon' ||
                  deviceForm.get('meterSupplier')['value'] === 'Remon_2024'
                "
              >
                <label>Device Model</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="meterModel"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.meterModel.errors
                  }"
                >
                  <option selected value="Wim-82A">Wim-82A</option>
                </select>
                <div
                  *ngIf="typesubmit && type.meterModel.errors"
                  class="text-danger"
                >
                  Please select a meterModel Configuration.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Connection Type</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="connectionType"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.connectionType.errors
                  }"
                >
                  <option selected value="1P-2W">1P-2W</option>
                  <option value="2P-3W">2P-3W</option>
                  <option value="3P-3W">3P-3W</option>
                  <option value="3P-4W">3P-4W</option>
                </select>
                <div
                  *ngIf="typesubmit && type.connectionType.errors"
                  class="text-danger"
                >
                  Please select a connectionType Configuration.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Part_Count Type</label>

                <select
                  type="selected"
                  class="form-control"
                  formControlName="partcountType"
                  [ngClass]="{
                    'is-invalid': typesubmit && type.partcountType.errors
                  }"
                >
                  <option selected value="digital">Digital</option>
                  <option value="AI">AI Modle</option>
                </select>
                <div
                  *ngIf="typesubmit && type.partcountType.errors"
                  class="text-danger"
                >
                  Please select a partcountType Configuration.
                </div>
              </div>
            </div>
            <hr />
            <div
              class="form-group col-md-6"
              [hidden]="
                loginRole == 'CUSTOMER' &&
                deviceForm.get('pins')['controls'].length == 0
              "
            >
              <h4 class="card-title">
                Pin Configuration
                <span style="position: absolute; margin-left: 2%">
                  <i
                    class="fas fa-plus-circle"
                    *ngIf="deviceForm.get('pins')['controls'].length < 2"
                    (click)="addDigitalPin()"
                    [hidden]="loginRole == 'CUSTOMER'"
                    style="
                      color: rgb(72, 255, 0);
                      cursor: pointer;
                      margin-left: 10px;
                    "
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Add"
                  ></i>
                </span>
              </h4>
            </div>
            <div formArrayName="pins">
              <div
                class="row"
                *ngFor="
                  let pin of deviceForm.get('pins')['controls'];
                  let i = index
                "
                [formGroupName]="i"
              >
                <div class="form-group col-md-1">
                  <label for="pinIndex{{ i }}">Index</label>
                  <input
                    id="pinIndex{{ i }}"
                    type="number"
                    class="form-control"
                    formControlName="index"
                    placeholder="Name"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="pinName{{ i }}">Pin Name</label>
                  <input
                    id="pinName{{ i }}"
                    type="text"
                    class="form-control"
                    formControlName="pinName"
                    placeholder="Name"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('pins.' + i + '.pinName').errors
                    }"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="pinType{{ i }}">Pin Type</label>
                  <select
                    id="pinType{{ i }}"
                    type="selected"
                    class="form-control"
                    formControlName="pinType"
                  >
                    <option selected value="none">None</option>
                    <option selected value="status">Status</option>
                    <!-- <option value="timer">Timer</option> -->
                    <option value="counter">Counter</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="partConfig{{ i }}">Part Config</label>
                  <select
                    id="partConfig{{ i }}"
                    type="selected"
                    class="form-control"
                    formControlName="partConfig"
                  >
                    <!-- <option selected value="true">True</option>
                    <option value="false">False</option> -->
                    <option
                      *ngFor="let config of partConfigOption; let j = index"
                      [value]="config"
                      [attr.hidden]="isOptionDisabled(j) ? true : null"
                    >
                      {{ config }}
                    </option>
                  </select>
                </div>
                <i
                  class="fas fa-minus-circle col-md-1"
                  [hidden]="loginRole == 'CUSTOMER'"
                  style="color: red; cursor: pointer; margin-top: 2.5rem"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Remove"
                  (click)="removeArrayItem(i)"
                ></i>
              </div>
            </div>
            <hr />
            <div
              class="form-group col-md-6"
              [hidden]="
                loginRole == 'CUSTOMER' &&
                deviceForm.get('analog')['controls'].length == 0
              "
            >
              <h4 class="card-title">
                Analog Configuration
                <span style="position: absolute; margin-left: 2%">
                  <i
                    class="fas fa-plus-circle"
                    *ngIf="deviceForm.get('analog')['controls'].length < 4"
                    (click)="addAnalogPin()"
                    [hidden]="loginRole == 'CUSTOMER'"
                    style="
                      color: rgb(72, 255, 0);
                      cursor: pointer;
                      margin-left: 10px;
                    "
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Add"
                  ></i>
                </span>
              </h4>
            </div>
            <div formArrayName="analog">
              <div
                class="row"
                *ngFor="
                  let analog of deviceForm.get('analog')['controls'];
                  let i = index
                "
                [formGroupName]="i"
              >
                <div class="form-group col-md-1">
                  <label>Index</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="index"
                    placeholder="Index"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Analog Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="analogName"
                    placeholder="Name"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.analogName').errors
                    }"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Channel</label>
                  <select
                    type="selected"
                    class="form-control"
                    formControlName="channel"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.channel').errors
                    }"
                  >
                    <option selected value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <label>Pin Type</label>
                  <select
                    type="selected"
                    class="form-control"
                    formControlName="portType"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.portType').errors
                    }"
                  >
                    <option selected value="voltage">Voltage</option>
                    <option value="current">4-20mA</option>
                    <option value="CT">CT</option>
                    <option value="None">None</option>

                    <!-- <option value="counter">Counter</option> -->
                  </select>
                </div>
                <div
                  class="form-group col-md-2"
                  *ngIf="
                    deviceForm.get('analog.' + i + '.portType').value ===
                    'voltage'
                  "
                >
                  <label>Multiple Factor</label>
                  <input
                    type="number"
                    class="form-control"
                    maxlength="3"
                    formControlName="multiFactor"
                    placeholder="0"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.multiFactor').errors
                    }"
                  />
                </div>
                <div
                  class="form-group col-md-2"
                  *ngIf="
                    deviceForm.get('analog.' + i + '.portType').value ===
                    'voltage'
                  "
                >
                  <label>Division Factor</label>
                  <input
                    type="number"
                    class="form-control"
                    maxlength="4"
                    formControlName="divisionFactor"
                    placeholder="0"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.divisionFactor').errors
                    }"
                  />
                </div>
                <div
                  class="form-group col-md-2"
                  *ngIf="
                    deviceForm.get('analog.' + i + '.portType').value ===
                      'current' ||
                    deviceForm.get('analog.' + i + '.portType').value === 'None'
                  "
                >
                  <label>Lower Limit</label>
                  <input
                    type="number"
                    class="form-control"
                    maxlength="3"
                    formControlName="lowerLimit"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.lowerLimit').errors
                    }"
                  />
                </div>
                <div
                  class="form-group col-md-2"
                  *ngIf="
                    deviceForm.get('analog.' + i + '.portType').value ===
                      'current' ||
                    deviceForm.get('analog.' + i + '.portType').value === 'None'
                  "
                >
                  <label>Upper Limit</label>
                  <input
                    type="number"
                    class="form-control"
                    maxlength="3"
                    formControlName="upperLimit"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.upperLimit').errors
                    }"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Sensor Output Type</label>
                  <select
                    type="selected"
                    class="form-control"
                    formControlName="sensorOutputType"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.sensorOutputType')
                          .errors
                    }"
                    (change)="updateValue(i)"
                  >
                    <option selected value="-1">None</option>
                    <option value="0">ADC_RAW</option>
                    <option value="1">ADC_VMAX</option>
                    <option value="2">ADC_VMIN</option>
                    <option value="3">ADC_VPP</option>
                    <option value="4">ADC_VP</option>
                    <option value="5">ADC_VRMS</option>
                    <option value="6">ADC_VAVG</option>
                  </select>
                </div>
                <div
                  class="form-group col-md-2"
                  *ngIf="
                    deviceForm.get('analog.' + i + '.portType').value === 'CT'
                  "
                >
                  <label>CT Ratio</label>
                  <input
                    type="number"
                    class="form-control"
                    maxlength="3"
                    formControlName="ctRatio"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.ctRatio').errors
                    }"
                  />
                </div>
                <div
                  class="form-group col-md-2"
                  *ngIf="
                    deviceForm.get('analog.' + i + '.portType').value === 'CT'
                  "
                >
                  <label>Sacle factor</label>
                  <input
                    type="number"
                    class="form-control"
                    maxlength="3"
                    formControlName="scaleFactor"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('analog.' + i + '.scaleFactor').errors
                    }"
                  />
                </div>
                <i
                  class="fas fa-minus-circle col-md-1"
                  [hidden]="loginRole == 'CUSTOMER'"
                  style="color: red; cursor: pointer; margin-top: 2.5rem"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Remove"
                  (click)="removeAnalogItem(i)"
                ></i>
              </div>
            </div>
            <hr />
            <div
              class="form-group col-md-6"
              [hidden]="
                loginRole == 'CUSTOMER' &&
                deviceForm.get('ct')['controls'].length == 0
              "
            >
              <h4 class="card-title">
                Current Configuration
                <span style="position: absolute; margin-left: 2%">
                  <i
                    class="fas fa-plus-circle"
                    *ngIf="deviceForm.get('ct')['controls'].length < 2"
                    (click)="addCurrentPin()"
                    [hidden]="loginRole == 'CUSTOMER'"
                    style="
                      color: rgb(72, 255, 0);
                      cursor: pointer;
                      margin-left: 10px;
                    "
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Add"
                  ></i>
                </span>
              </h4>
            </div>
            <div formArrayName="ct">
              <div
                class="row"
                *ngFor="
                  let ct of deviceForm.get('ct')['controls'];
                  let i = index
                "
                [formGroupName]="i"
              >
                <div class="form-group col-md-1">
                  <label>Index</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="index"
                    placeholder="Name"
                  />
                </div>
                <div class="form-group col-md-1">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="ctName"
                    placeholder="Name"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('ct.' + i + '.ctName').errors
                    }"
                  />
                </div>
                <div class="form-group col-md-1">
                  <label>CT Rate</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="ctRate"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('ct.' + i + '.ctRate').errors
                    }"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Upper Control Limit</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="upperLimit"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('ct.' + i + '.upperLimit').errors
                    }"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Upper Set Limit</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="upperSet"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('ct.' + i + '.upperSet').errors
                    }"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Lower Control Limit</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="lowerLimit"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('ct.' + i + '.lowerLimit').errors
                    }"
                    placeholder="348"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Lower set Limit</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="lowerSet"
                    placeholder="348"
                    [ngClass]="{
                      'is-invalid':
                        typesubmit &&
                        deviceForm.get('ct.' + i + '.lowerSet').errors
                    }"
                  />
                </div>
                <i
                  class="fas fa-minus-circle col-md-1"
                  [hidden]="loginRole == 'CUSTOMER'"
                  style="color: red; cursor: pointer; margin-top: 2.5rem"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Remove"
                  (click)="removeCurrentItem(i)"
                ></i>
              </div>
            </div>
            <hr />
            <div
              class="form-group col-md-6"
              [hidden]="
                loginRole == 'CUSTOMER' &&
                deviceForm.get('ct')['controls'].length == 0
              "
            >
              <h4 class="card-title">EMIC Configuration</h4>
            </div>
            <div formArrayName="eMICConfig">
              <div
                class="row"
                *ngFor="
                  let ct of deviceForm.get('eMICConfig')['controls'];
                  let k = index
                "
                [formGroupName]="k"
              >
                <div class="form-group col-md-3">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="eMICName"
                    placeholder="Frequency"
                    disabled
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>eMIC Type:</label>
                  <div
                    class="row"
                    *ngIf="ct.get('eMICName')?.value === 'Frequency'"
                  >
                    <div *ngFor="let type of FrequencySequency; let j = index">
                      <div class="form-check form-check-inline col-md-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="eMICType"
                          [id]="'inlineRadio2-' + k + '-' + j"
                          [value]="type"
                        />
                        <label
                          class="form-check-label"
                          [for]="'inlineRadio2-' + k + '-' + j"
                        >
                          {{ type }}</label
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="ct.get('eMICName')?.value === 'Phase Sequence'"
                  >
                    <div *ngFor="let type of phaseSequence; let j = index">
                      <div class="form-check form-check-inline col-md-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="eMICType"
                          [id]="'inlineRadio1-' + k + '-' + j"
                          [value]="type"
                        />
                        <label
                          class="form-check-label"
                          [for]="'inlineRadio1-' + k + '-' + j"
                        >
                          {{ type }}</label
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="row align-items-center"
                    *ngIf="
                      ct.get('eMICName')?.value === 'PGA Gain Configuration'
                    "
                  >
                    <div class="col-md-6">
                      <input
                        class="form-control"
                        type="number"
                        formControlName="eMICType"
                        placeholder="PGA Gain"
                        (input)="onInput($event, k)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-2">
              <div>
                <button type="submit" class="btn btn-primary mr-1">
                  <span *ngIf="!isEdit">Submit</span>
                  <span *ngIf="isEdit">Update</span>
                </button>
                <button
                  type="reset"
                  class="btn btn-secondary"
                  (click)="cancel()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <ng-template #machineType let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">Machine Type</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form
          (ngSubmit)="saveData()"
          [formGroup]="machineTypeform"
          autocomplete="off"
        >
          <div class="form-group">
            <label for="deviceType">Machine Type</label>
            <input
              type="text"
              class="form-control"
              id="deviceType"
              placeholder="Enter Machine Type"
              formControlName="deviceType"
              [ngClass]="{ 'is-invalid': submit && form.deviceType.errors }"
            />
            <div
              *ngIf="submit && form.deviceType.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.deviceType.errors.required"
                >Machine Type is mandatory.</span
              >
            </div>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <input
              type="text"
              class="form-control"
              id="description"
              placeholder="Enter Description"
              formControlName="description"
              [ngClass]="{ 'is-invalid': submit && form.description.errors }"
            />
            <div
              *ngIf="submit && form.description.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.description.errors.required"
                >Description is mandatory.</span
              >
            </div>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-success">Save</button>
            <button
              type="button"
              class="btn btn-danger ml-1"
              (click)="cancelModal()"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #deletetemp role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">Delete Machine</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body card-body">
      <div class="example-container">
        <form>
          <div>
            <p>Are you sure to Delete?</p>
          </div>
          <button
            type="submit"
            class="btn btn-danger round waves-effect headr"
            (click)="delete(delMachId, deviceId)"
          >
            Delete
          </button>
          &nbsp;
          <button
            type="submit"
            class="btn btn-info round waves-effect headr"
            (click)="modal('Cross click')"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!-- container-fluid -->
