import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
// import * as echarts from "echarts/core";
import * as echarts from "echarts";

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts";
import { CustomChart } from "echarts";
import { CanvasRenderer } from "echarts";
@Component({
  selector: "app-partcountchart",
  templateUrl: "./partcountchart.component.html",
  styleUrls: ["./partcountchart.component.scss"],
})

/**
 * E-chart component
 */
export class PartcountchartComponent implements OnInit, AfterViewInit {
  // @ViewChild("ganttChart") ganttChartElement: ElementRef;
  @Input() gaugeArray: any;

  ganttChart: any = [];

  echartsInstance: any;
  constructor(private elementRef: ElementRef) {}

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];

    if (this.gaugeArray["hour"].length != 0) {
      this.plotHourlychart(this.gaugeArray);
      // this.chartData(data);
      // this.gaugeChart(this.data);
    }
    console.log(this.gaugeArray);
    // this.chartData();
  }
  option: any;
  ngOnChanges(): void {
    console.log(
      // this.gaugeArray[0]["time"][0],
      this.gaugeArray,
      "resss.............."
    );
    if (this.gaugeArray["hour"].length != 0) {
      // this.chartData(data);
      this.plotHourlychart(this.gaugeArray);
      // this.gaugeChart(this.gaugeArray);
      // this.gaugeChart(this.data);
    }
  }
  ngAfterViewInit(): void {}
  plotHourlychart(data) {
    // let myChart = echarts.init(this.ganttChartElement.nativeElement);

    this.option = {
      xAxis: {
        type: "category",
        data: data["hour"],
      },
      yAxis: {
        type: "value",
      },
      color: "blue",
      series: [
        {
          data: data["partcount"],
          type: "bar",
          label: {
            show: true,
            position: "top",
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
        {
          type: "slider",
        },
      ],
    };
  }
}
