import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  NgbAccordionModule,
  NgbNavModule,
  NgbTypeaheadModule,
  NgbPaginationModule,
  NgbCollapseModule,
  NgbTooltipModule,
  NgbModalModule,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";

import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ArchwizardModule } from "angular-archwizard";
import { NgxMaskModule } from "ngx-mask";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";
import { ColorPickerModule } from "ngx-color-picker";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { UiModule } from "../../shared/ui/ui.module";
import { FormRoutingModule } from "./form-routing.module";
import { FullCalendarModule } from "@fullcalendar/angular";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { DeviceComponent } from "./deviceList/deviceadd.component";
import { UserComponent } from "./users/user.component";
import { ReasonsComponent } from "./reasons/reasons.component";
import { CustomerInfoComponent } from "./customer_info/customer_info.component";
import { ShiftInfoComponent } from "./shift_info/shift_info.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { ProgramListComponent } from './program-list/program-list.component';
import { ProductionPlanningComponent } from './production-planning/production-planning.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "https://httpbin.org/post",
  maxFilesize: 50,
};
@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    DeviceComponent,
    ShiftInfoComponent,
    CustomerInfoComponent,
    UserComponent,
    ReasonsComponent,
    ProgramListComponent,
    ProductionPlanningComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormRoutingModule,
    NgxIntlTelInputModule,
    UiModule,
    CKEditorModule,
    ArchwizardModule,
    NgbPaginationModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbCollapseModule,
    Ng2SearchPipeModule,
    NgbAccordionModule,
    NgMultiSelectDropDownModule,
    NgbNavModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    UiSwitchModule,
    ColorPickerModule,
    NgbTypeaheadModule,
    FullCalendarModule,
    NgbDatepickerModule,
    DropzoneModule,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ],
})
export class FormModule {}
